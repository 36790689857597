import React, {useLayoutEffect} from 'react';

import {useAppDispatch, useAppSelector} from 'app/config/store';
import {logout} from 'app/shared/reducers/authentication';

export const Logout = () => {
  const logoutUrl = useAppSelector(state => state.authentication.logoutUrl);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(logout());
  });

  return (
    <div className="p-5">
      <span className={"display-6"}>Logged out successfully ! 👋</span>
    </div>
  );
};

export default Logout;
