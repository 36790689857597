import React from 'react';
import BadgeArray from "app/shared/component/badge-array";

export const ApplicationCell = ({data}) => {

    if (data.length === 0) {
        return (
            <span className="text-secondary"></span>
        );
    }

    return (
        <BadgeArray data={data}/>
    );
};

export default ApplicationCell;
