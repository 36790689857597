import axios from 'axios';
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {createEntitySlice, EntityState, IQueryParams, serializeAxiosError} from 'app/shared/reducers/reducer.utils';
import {defaultValue, ICategory} from "app/shared/model/category.model";

const initialState: EntityState<ICategory> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/categories';

export const getEntities = createAsyncThunk('categories/fetch_entity_list',
    async ({
               page,
               size,
               sort
           }: IQueryParams) => {
        const requestUrl = `${apiUrl}`;
        return axios.get<ICategory[]>(requestUrl);
    });

export const createEntity = createAsyncThunk(
    'categories/create_entity',
    async (entity: ICategory, thunkAPI) => {
        const result = await axios.post<ICategory>(apiUrl, cleanEntity(entity));
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);

export const updateEntity = createAsyncThunk(
    'categories/update_entity',
    async (entity: ICategory, thunkAPI) => {
        const result = await axios.put<ICategory>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);


export const partialUpdateEntity = createAsyncThunk(
    'categories/partial_update_entity',
    async (entity: ICategory, thunkAPI) => {
        const result = await axios.patch<ICategory>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);

export const deleteEntity = createAsyncThunk(
    'categories/delete_entity',
    async (id: string | number, thunkAPI) => {
        const requestUrl = `${apiUrl}/${id}`;
        const result = await axios.delete<ICategory>(requestUrl);
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);


// slice

export const CategorySlice = createEntitySlice({
    name: 'category',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(deleteEntity.fulfilled, state => {
                state.updating = false;
                state.updateSuccess = true;
                state.entity = {};
            })
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const {data} = action.payload;

                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
                state.updating = false;
                state.loading = false;
                state.updateSuccess = true;
                state.entity = action.payload.data;
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.updating = true;
            });
    },
});

export const {reset} = CategorySlice.actions;

// Reducer
export default CategorySlice.reducer;
