import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";
import {Route} from "react-router-dom";
import React from "react";
import ApplicationManage from "app/entities/application/application-manage";

const ApplicationRoutes = () => (
    <ErrorBoundaryRoutes>
        <Route index element={<ApplicationManage/>}/>
    </ErrorBoundaryRoutes>
);

export default ApplicationRoutes;
