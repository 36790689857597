import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {getChangeLogByTextContentId} from "app/entities/change_log/change-log.utils";
import {convertDateTimeStampFromServer} from "app/shared/util/date-utils";
import {AgGridReact} from "ag-grid-react";
import DataChangeViewer from "app/shared/component/custom-json-viewer";
import {Badge, Spinner} from "reactstrap";
import Avatar from "react-avatar";

const containerStyle = {
    width: '100%',
    height: '40vh',
};

export const ChangeLogTextContent = forwardRef<any, any>((props, ref) => {

        const textContentId = props["props"].textContentId;
        const [data, setData] = useState<[]>([])
        const [loading, setLoading] = useState(true)

        const gridRef = useRef(null);

        const defaultColumnDef = {
            wrapHeaderText: true,
            wrapText: true,
            autoHeight: true,
            autoHeaderHeight: true,
        };

        const columnDef: any = [
            {
                field: 'timeStamp',
                headerName: 'Timestamp',
                cellRenderer: (params: any) => {
                    return <b> {convertDateTimeStampFromServer(params.value)} </b>
                },
                maxWidth: 165,
            },
            {
                field: 'actor',
                cellRenderer: (params: any) => {
                    return <div className={"d-flex justify-content-center align-self-center"}>
                        <Avatar name={params.value} round={true} size={"35px"}/>
                    </div>
                },
                maxWidth: 80,
            },
            {
                field: 'actor',
                headerName: "Actor Full Name",
                cellRenderer: (params: any) => {
                    return <b className={"text-primary text-truncate"}> {params.value} </b>
                },
                maxWidth: 165
            },
            {
                field: 'actionType',
                maxWidth: 145,
                cellRenderer: (params: any) => {
                    return <Badge
                        color={params.value === "DELETE" ? "danger" : params.value === "CREATE" ? "success" : "primary"}
                        pill={false}
                        className={"me-1"}>{params.value}
                    </Badge>
                }
            },
            {
                field: 'entityType',
                maxWidth: 135
            },
            {
                field: 'before',
                cellRenderer: (params: any) => {
                    return <DataChangeViewer data={params.value}/>
                },
            },
            {
                field: 'after',
                cellRenderer: (params: any) => {
                    return <DataChangeViewer data={params.value}/>
                },
            },
        ];

        useEffect(() => {
            fetchData();
        }, [])

        useImperativeHandle(ref, () => ({
            reInit() {
                gridRef.current.api.sizeColumnsToFit();
            }
        }))

        const fetchData = async () => {
            setLoading(true);
            getChangeLogByTextContentId(textContentId)
                .then((res: any) => {
                        setData(res.data);
                    }
                ).finally(() => {
                    setLoading(false);
                }
            )
        }

        const onGridReady = (params) => {
            params.api.sizeColumnsToFit();
        }

        // Need this to fix, column width inconsistency
        if (loading) {
            return <Spinner className={"ms-2 mt-2"}/>
        } else {
            return (
                <>
                    <div style={containerStyle} className="mt-2 ag-theme-alpine ">
                        <AgGridReact
                            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                            overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No data founds</span>'}
                            ref={gridRef}
                            rowData={data}
                            columnDefs={columnDef}
                            defaultColDef={defaultColumnDef}
                            animateRows={true}
                            groupDefaultExpanded={1}
                            rowHeight={25}
                            onGridReady={onGridReady}
                            enableCellTextSelection={true}
                            pagination={true}
                            paginationAutoPageSize={true}
                            onRowDataUpdated={onGridReady}
                        />
                    </div>
                </>
            )
        }
    }
);


export default ChangeLogTextContent;
