import axios from 'axios';
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {createEntitySlice, EntityState, IQueryParams, serializeAxiosError} from 'app/shared/reducers/reducer.utils';
import {defaultValue, ITranslation} from 'app/shared/model/translation.model';

const initialState: EntityState<ITranslation> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
};

const apiUrl = 'api/translations';

// Actions

export const getEntities = createAsyncThunk('translation/fetch_entity_list', async ({
                                                                                        page,
                                                                                        size,
                                                                                        sort
                                                                                    }: IQueryParams) => {
    const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
    return axios.get<ITranslation[]>(requestUrl);
});

export const getEntitiesFilterByCode = createAsyncThunk('translation/fetch_entity_list_filter', async (filter: string) => {
    const requestUrl = `${apiUrl}?${filter}`;
    return axios.get<ITranslation[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
    'translation/fetch_entity',
    async (id: string | number) => {
        const requestUrl = `${apiUrl}/${id}`;
        return axios.get<ITranslation>(requestUrl);
    },
    {serializeError: serializeAxiosError}
);

export const createEntity = createAsyncThunk(
    'translation/create_entity',
    async (entity: ITranslation, thunkAPI) => {
        const result = await axios.post<ITranslation>(apiUrl, cleanEntity(entity));
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);

export const updateEntity = createAsyncThunk(
    'translation/update_entity',
    async (entity: ITranslation, thunkAPI) => {
        const result = await axios.put<ITranslation>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);

export const partialUpdateEntity = createAsyncThunk(
    'translation/partial_update_entity',
    async (entity: ITranslation, thunkAPI) => {
        const result = await axios.patch<ITranslation>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);

export const deleteEntity = createAsyncThunk(
    'translation/delete_entity',
    async (id: string | number, thunkAPI) => {
        const requestUrl = `${apiUrl}/${id}`;
        const result = await axios.delete<ITranslation>(requestUrl);
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);

// slice

export const TranslationSlice = createEntitySlice({
    name: 'translation',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data;
            })
            .addCase(deleteEntity.fulfilled, state => {
                state.updating = false;
                state.updateSuccess = true;
                state.entity = {};
            })
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const {data, headers} = action.payload;

                return {
                    ...state,
                    loading: false,
                    entities: data,
                    totalItems: parseInt(headers['x-total-count'], 10),
                };
            })
            .addMatcher(isFulfilled(getEntitiesFilterByCode), (state, action) => {
                const {data, headers} = action.payload;

                return {
                    ...state,
                    loading: false,
                    entities: data,
                    totalItems: parseInt(headers['x-total-count'], 10),
                };
            })
            .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
                state.updating = false;
                state.loading = false;
                state.updateSuccess = true;
                state.entity = action.payload.data;
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.updating = true;
            });
    },
});

export const {reset} = TranslationSlice.actions;

// Reducer
export default TranslationSlice.reducer;
