import React from 'react';
import {Route} from 'react-router-dom';
import Loadable from 'react-loadable';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import EntitiesRoutes from 'app/entities/routes';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import {AUTHORITIES} from 'app/config/constants';
import Redirect from './modules/redirect/redirect';
import authorizationUri from './config/oauth';
import Callback from './modules/login/callback';
import InvitationForm from "app/modules/invitation/invitation-form";

const loading = <div>loading ...</div>;

const Account = Loadable({
    loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
    loading: () => loading,
});

const Admin = Loadable({
    loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
    loading: () => loading,
});

const AppRoutes = () => {
    return (
        <div className="view-routes">
            <ErrorBoundaryRoutes>
                <Route index element={<Home/>}/>
                <Route path="login" element={<Redirect url={authorizationUri}/>}/>
                <Route path="callback" element={<Callback/>}/>
                <Route path="sso" element={<Redirect url={authorizationUri}/>}/>
                <Route path="logout" element={<Logout/>}/>
                <Route path="account">
                    <Route
                        path="*"
                        element={
                            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.AGENT, AUTHORITIES.DEVELOPER]}>
                                <Account/>
                            </PrivateRoute>
                        }
                    />
                    {/*<Route path="register" element={<Register />} />*/}
                    <Route path="activate" element={<Activate/>}/>
                    <Route path="reset">
                        <Route path="request" element={<PasswordResetInit/>}/>
                        <Route path="finish" element={<PasswordResetFinish/>}/>
                    </Route>
                </Route>
                <Route path="invitation-form" element={
                    <InvitationForm/>
                }/>
                <Route
                    path="admin/*"
                    element={
                        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.DEVELOPER]}>
                            <Admin/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="*"
                    element={
                        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.AGENT, AUTHORITIES.DEVELOPER]}>
                            <EntitiesRoutes/>
                        </PrivateRoute>
                    }
                />
                <Route path="*" element={<PageNotFound/>}/>
            </ErrorBoundaryRoutes>
        </div>
    );
};

export default AppRoutes;
