import React, {useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {isNumber, Translate, translate, ValidatedField, ValidatedForm} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useAppDispatch, useAppSelector} from 'app/config/store';
import {createEntity, getEntity, reset, updateEntity} from './translation.reducer';

export const TranslationUpdate = () => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const {id} = useParams<'id'>();
    const isNew = id === undefined;

    const translationEntity = useAppSelector(state => state.translation.entity);
    const loading = useAppSelector(state => state.translation.loading);
    const updating = useAppSelector(state => state.translation.updating);
    const updateSuccess = useAppSelector(state => state.translation.updateSuccess);

    const handleClose = () => {
        navigate('/translation' + location.search);
    };

    useEffect(() => {
        if (isNew) {
            dispatch(reset());
        } else {
            dispatch(getEntity(id));
        }
    }, []);

    useEffect(() => {
        if (updateSuccess) {
            handleClose();
        }
    }, [updateSuccess]);

    const saveEntity = values => {
        const entity = {
            ...translationEntity,
            ...values,
        };

        if (isNew) {
            dispatch(createEntity(entity));
        } else {
            dispatch(updateEntity(entity));
        }
    };

    const defaultValues = () =>
        isNew
            ? {}
            : {
                ...translationEntity,
            };

    return (
        <div>
            <Row className="justify-content-center">
                <Col md="8">
                    <h2 id="messageStringApp.translation.home.createOrEditLabel"
                        data-cy="TranslationCreateUpdateHeading">
                        <Translate contentKey="messageStringApp.translation.home.createOrEditLabel">Create or edit a
                            Translation</Translate>
                    </h2>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md="8">
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
                            {!isNew ? (
                                <ValidatedField
                                    name="id"
                                    required
                                    readOnly
                                    id="translation-id"
                                    label={translate('messageStringApp.translation.id')}
                                    validate={{required: true}}
                                />
                            ) : null}
                            <ValidatedField
                                label={translate('messageStringApp.translation.langId')}
                                id="translation-langId"
                                name="langId"
                                data-cy="langId"
                                type="text"
                                validate={{
                                    required: {value: true, message: translate('entity.validation.required')},
                                    validate: v => isNumber(v) || translate('entity.validation.number'),
                                }}
                            />
                            <ValidatedField
                                label={translate('messageStringApp.translation.textContentId')}
                                id="translation-textContentId"
                                name="textContentId"
                                data-cy="textContentId"
                                type="text"
                                validate={{
                                    required: {value: true, message: translate('entity.validation.required')},
                                    validate: v => isNumber(v) || translate('entity.validation.number'),
                                }}
                            />
                            <ValidatedField
                                label={translate('messageStringApp.translation.transString')}
                                id="translation-transString"
                                name="transString"
                                data-cy="transString"
                                type="text"
                                validate={{
                                    required: {value: true, message: translate('entity.validation.required')},
                                }}
                            />
                            <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/translation"
                                    replace color="info">
                                <FontAwesomeIcon icon="arrow-left"/>
                                &nbsp;
                                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
                            </Button>
                            &nbsp;
                            <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit"
                                    disabled={updating}>
                                <FontAwesomeIcon icon="save"/>
                                &nbsp;
                                <Translate contentKey="entity.action.save">Save</Translate>
                            </Button>
                        </ValidatedForm>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default TranslationUpdate;
