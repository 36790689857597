import React, {useEffect, useRef, useState} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {deleteEntity, getEntity, partialUpdateEntity} from './text-content.reducer';
import {getEntities as appGetEntities} from "app/entities/application/application.reducer";
import {useAppDispatch, useAppSelector} from "app/config/store";
import {confirmAlert} from 'react-confirm-alert';
import GenericHeader from "app/shared/component/generic-manage-header";
import {faLaptopCode} from "@fortawesome/free-solid-svg-icons";
import GenericManageTable from "app/shared/component/generic-manage-table";
import {getEntitiesFilterByCode} from "app/entities/translation/translation.reducer";
import {ActionMeta, OnChangeValue} from "react-select/dist/declarations/src/types";
import {toast} from "react-toastify";
import {AUTHORITIES} from 'app/config/constants';
import {hasAnyAuthority} from 'app/shared/auth/private-route';
import {getActiveEntities} from "app/entities/language/language.reducer";
import LangWithFlag from "app/shared/component/lang-with-flag";
import ChangeLogTextContent from "app/entities/change_log/change-log-text-content";

const TabName = {
    APP: "APP",
    TRANS: "TRANS",
    HISTORY: "HISTORY",
}

const customStyles = {
    input: styles => {
        return {
            ...styles,
            height: '0.8em'
        };
    }
};

export const TextContentItem = ({data, onCloseModal, ...props}) => {

    const dispatch = useAppDispatch();

    const [string, setString] = useState<string>(data.code);
    const [app, setApp] = useState<any>(null);
    const [activeTab, setActiveTab] = useState(TabName.TRANS);
    const [appOptions, setAppOptions] = useState([]);

    const textCodeApplications = useAppSelector(state => state.textContent.entity.applications);
    const textCodeLoading = useAppSelector(state => state.textContent.loading);
    const textCodeTranslations = useAppSelector(state => state.translation.entities);
    const applicationList = useAppSelector(state => state.application.entities);
    const applicationLoading = useAppSelector(state => state.application.loading);
    const languageLookUpMap = useAppSelector(state => state.language.lookUpMap);

    const isDeveloper = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.DEVELOPER]));

    // Ref
    const historyRef = useRef(null);

    const colDefTrans: any = [
        {
            field: 'langId',
            headerName: 'Language',
            cellStyle: {textAlign: 'left'},
            cellRenderer: (params: any) => {
                return <LangWithFlag code={languageLookUpMap[params.value]?.langCode}/>
            }
        },
        {
            field: 'transString',
            headerName: 'Translations',
            cellStyle: {textAlign: 'left'}
        }
    ];

    const colDefApplication: any = [
        {
            field: 'name',
            headerName: 'Name',
            cellStyle: {textAlign: 'left'},
            flex: 1
        },
        {
            field: 'description',
            headerName: 'Description',
            cellStyle: {textAlign: 'left'},
            flex: 1
        }
    ];

    useEffect(() => {
        dispatch(getEntitiesFilterByCode(`textContentId.equals=${data.id}`));
        dispatch(appGetEntities({}));
        dispatch(getEntity(data.id));
        dispatch(getActiveEntities({}));
    }, [])

    useEffect(() => {
        const buildAppOptions = applicationList.map(data => {
            return {
                value: data,
                label: data.name,
            };
        });
        setAppOptions(buildAppOptions);
    }, [applicationList])

    const onUpdate = async (e) => {
        e.preventDefault();
        const newTextContent = {
            ...data,
            id: data.id,
            code: string
        }
        await dispatch(partialUpdateEntity(newTextContent));
        // await onClose();
    }

    const onConfirmDel = (e, data) => {
        e.preventDefault();
        confirmAlert({
                customUI: ({onClose}) => {
                    return (
                        <div className={"border border-2 border-dark p-4"}>
                            <h5 className={"display-6 text-danger"}>
                                {`Are you sure to do delete this code`}
                            </h5>
                            <p>{`All translations from ${Object.keys(data.allTranslations).length} languages will be deleted`}</p>
                            <button className={"btn btn-danger"}
                                    onClick={async () => {
                                        await dispatch(deleteEntity(data.id));
                                        onClose()
                                        await onCloseModal();
                                    }}
                            >
                                Yes
                            </button>
                            <button
                                className={"ms-4 btn btn-dark"}
                                onClick={async () => {
                                    onClose();
                                }}
                            >
                                No
                            </button>
                        </div>
                    );
                }
            }
        )
    }

    const onSelectApp = async (newValue: OnChangeValue<any, any>, actionMeta: ActionMeta<any>) => {
        setApp(newValue.value);
    };

    const onAssign = async (e) => {
        e.preventDefault();
        if (app == null) {
            toast.error("Nothing selected !");
            return
        }
        const newTextContent = {
            id: data.id,
            applications: [...textCodeApplications, app]
        }
        await dispatch(partialUpdateEntity(newTextContent));
        await dispatch(getEntity(data.id));
        setApp(null);
    }

    // @ts-nocheck
    return (
        <div>
            <GenericHeader
                icon={faLaptopCode}
                title={"Text Code Detail"}
                size={0}
                meta={false}
            />

            <hr className="mt-3 mb-2"/>

            <form className="row g-3">
                <div className="col-md-2 d-none">
                    <label htmlFor="name" className="form-label">ID</label>
                    <input type="text"
                           className={"form-control form-control-sm"}
                           id="name"
                           value={data.id}
                           readOnly={true}
                    />
                </div>
                <div className="col-md-12">
                    <div className="row mt-3">
                        <div className="col-2">
                            <label htmlFor="applicationName" className="form-label">Application</label>
                            <div className="input-group">
                                <input type="applicationName"
                                       className={"form-control form-control-sm"}
                                       id="applicationName"
                                       value={data.applicationName}
                                       readOnly={true}
                                       disabled={true}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <label htmlFor="categoryName" className="form-label">Category</label>
                            <div className="input-group">
                                <input type="categoryName"
                                       className={"form-control form-control-sm"}
                                       id="categoryName"
                                       value={data.categoryName}
                                       readOnly={true}
                                       disabled={true}
                                />
                            </div>
                        </div>
                        <div className="col-8">
                            <label htmlFor="code" className="form-label">Code</label>
                            <div className="input-group">
                                <input type="code"
                                       className={"form-control form-control-sm"}
                                       id="code"
                                       value={string}
                                       onChange={(e) => setString(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <button className="btn btn-primary btn-sm" onClick={onUpdate}
                            disabled={textCodeLoading}>Update
                    </button>
                    {isDeveloper && (
                        <button className="btn btn-danger btn-sm ms-2"
                                onClick={(e) => onConfirmDel(e, data)}
                                disabled={textCodeLoading}
                        >Delete
                        </button>
                    )}
                </div>
            </form>

            <hr className="mt-3 mb-3"/>

            <div className={"mt-3"}>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={activeTab == TabName.TRANS ? 'active' : ''}
                                 onClick={() => setActiveTab(TabName.TRANS)}>
                            Translations
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab == TabName.HISTORY ? 'active' : ''}
                                 onClick={() => {
                                     setActiveTab(TabName.HISTORY)
                                     historyRef.current.reInit()
                                 }}>
                            History
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={TabName.TRANS}>

                        <GenericManageTable
                            colDef={colDefTrans}
                            data={textCodeTranslations}
                        />
                    </TabPane>
                    <TabPane tabId={TabName.HISTORY}>
                        <ChangeLogTextContent props={{textContentId: data.id}} ref={historyRef}/>
                    </TabPane>
                </TabContent>
            </div>
        </div>
    );
};

export default TextContentItem;
