import React, {useEffect} from 'react';
import {Spinner} from "reactstrap";

const Redirect = props => {
    const {url} = props;
    useEffect(() => {
        window.location.href = url;
        console.log(`Redirect ${url}`)
    }, [url]);

    return <h5 className={"display-6"}> 🚢 Redirecting... <Spinner/> </h5>;
};

export default Redirect;
