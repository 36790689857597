import React from 'react';
import {Translate} from 'react-jhipster';

import {NavItem, NavLink, NavbarBrand} from 'reactstrap';
import {NavLink as Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {within} from '@testing-library/react';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/logo.png" alt="Logo" className={"me-4"} style={{maxWidth: "2.5em", height: "auto"}}/>
  </div>
);

export const Brand = ({isAuthenticated = false}) => (
  <NavbarBrand tag={Link} to={isAuthenticated ? '/translation?page=0&sort=id&order=DESC' : '/'} className="brand-logo d-flex align-items-center">
    <BrandIcon/>
    <span className="brand-title">
      <Translate contentKey="global.title">Message Table</Translate>
    </span>
    <span className="navbar-version">{VERSION}</span>
  </NavbarBrand>
);

export const Home = ({isAuthenticated = false}) => (
  <NavItem>
    <NavLink tag={Link} to={isAuthenticated ? '/translation?page=0&sort=id&order=DESC' : '/'} className="d-flex align-items-center">
      <FontAwesomeIcon icon="home"/>
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </NavLink>
  </NavItem>
);
