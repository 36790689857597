import React, {useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCopy} from "@fortawesome/free-solid-svg-icons";

export const CopyableComponent = ({children = null, text, icon = true}) => {

    const [isCopy, setIsCopy] = useState(false);

    const onCopy = () => {
        setIsCopy(true);
    }

    if (icon) {
        return (
            <>
                <CopyToClipboard onCopy={onCopy} text={text} data-bs-toggle="tooltip" data-bs-placement="top"
                                 title="Copy to clipboard">
                    {!isCopy ? <FontAwesomeIcon className={"text-primary clickable me-3"} icon={faCopy}/>
                        : <FontAwesomeIcon className={"text-success clickable me-3"} icon={faCheck}/>}
                </CopyToClipboard>
                {children} {" "}
            </>
        )
    }

    return (
        <>
            <span className={'text-truncate text-left w-100 text-primary clickable'}>
                <CopyToClipboard onCopy={onCopy}
                                 text={text}
                                 data-bs-toggle="tooltip"
                                 data-bs-placement="top"
                                 className
                                 title="Copy to clipboard">
                    <span>{text}{" "}
                        {isCopy && <FontAwesomeIcon className={"ms-1 text-success"} icon={faCheck}/>}
                    </span>
                </CopyToClipboard>
            </span>
        </>
    )

};

export default CopyableComponent;
