import React, {useEffect, useState} from 'react';
import {faTable} from "@fortawesome/free-solid-svg-icons";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {Button, Spinner} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import {IStatsActivity, IStatsTranslation} from "app/shared/model/stats.model";
import {findAllActivitiesStats, findAllTranslationStats} from "app/entities/statistics/statistic.utils";
import {toast} from "react-toastify";
import GenericHeader from "app/shared/component/generic-manage-header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LangWithFlag from "app/shared/component/lang-with-flag";
import {useAppDispatch} from "app/config/store";
import {getEntities} from "app/entities/language/language.reducer";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar} from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    BarElement
)

const calculatePercentage = (totalTranslate, totalUntranslate) => {
    if (totalTranslate + totalUntranslate === 0) return 0;
    else return Math.ceil((totalTranslate / (totalTranslate + totalUntranslate)) * 100)
}

export const StatsDashBoard = () => {

    const dispatch = useAppDispatch();

    // STATE
    const [translationStats, setTranslationStats] = useState<IStatsTranslation[]>([])
    const [activitiesStats, setActivitiesStats] = useState<IStatsActivity[]>([])
    const [activitiesChart, setActivitiesChart] = useState<any>({})
    const [activitiesLabels, setActivitiesLabels] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)

    // DEFS
    const chartOption = {
        plugins: {
            title: {
                display: true,
                text: 'Activities Statistic',
                font: {
                    size: 35,
                }
            }
        },
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        legend: {
            display: false,
            labels: {
                font: {
                    size: 30
                }
            }
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };


    // HOOKS
    useEffect(() => {
        dispatch(getEntities({}));
        fetchData()
    }, [])

    useEffect(() => {
        if (!!activitiesStats) {
            const newActivitiesLabels = activitiesStats.map(
                s => s.systemDate
            );
            setActivitiesLabels(newActivitiesLabels)
        }
    }, [activitiesStats])

    useEffect(() => {
        if (!!activitiesStats && !!activitiesLabels) {
            const newActivitiesData = {
                labels: activitiesLabels,
                datasets: [
                    {
                        label: 'Total activities',
                        data: activitiesLabels.map((dateLabel) => {
                            const value = activitiesStats.find(s => s.systemDate === dateLabel)
                            if (!!value) return value.totalActivity
                            else return 0
                        }),
                        backgroundColor: 'rgb(255, 99, 132)',
                        stack: 'Stack 0',
                    },
                    {
                        label: 'Create activities',
                        data: activitiesLabels.map((dateLabel) => {
                            const value = activitiesStats.find(s => s.systemDate === dateLabel)
                            if (!!value) return value.totalCreateActivity
                            else return 0
                        }),
                        backgroundColor: 'rgb(75, 192, 192)',
                        stack: 'Stack 1',
                    },
                    {
                        label: 'Update activities',
                        data: activitiesLabels.map((dateLabel) => {
                            const value = activitiesStats.find(s => s.systemDate === dateLabel)
                            if (!!value) return value.totalUpdateActivity
                            else return 0
                        }),
                        backgroundColor: 'rgb(53, 162, 235)',
                        stack: 'Stack 1',
                    },
                    {
                        label: 'Delete activities',
                        data: activitiesLabels.map((dateLabel) => {
                            const value = activitiesStats.find(s => s.systemDate === dateLabel)
                            if (!!value) return value.totalDeleteActivity
                            else return 0
                        }),
                        backgroundColor: 'rgb(206, 0, 224)',
                        stack: 'Stack 1',

                    },
                ],
            };
            setActivitiesChart(newActivitiesData)
        }
    }, [activitiesStats, activitiesLabels]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const [activityStats, transStats] = await Promise.all([findAllActivitiesStats(), findAllTranslationStats()]);
            setTranslationStats(transStats.data)
            setActivitiesStats(activityStats.data)
            setLoading(false)
        } catch (error) {
            toast.error("Error when load statistic data")
            setLoading(false)
        }
    }

    if (loading) {
        return (
            <div className={"container-fluid "}>
                <div className={"col-12"}>
                    <GenericHeader
                        icon={faTable}
                        title={"Statistic Data"}
                        meta={false}
                        size={null}
                    />
                    <hr className="mt-3 mb-2"/>
                </div>
                <div className={"text-center"}>
                    <span className={"display-6"}>
                         📊 {" "}Loading statistic data...
                    </span>
                    <Spinner/>
                </div>
            </div>
        )
    }

    return (
        <div className={"fluid-container"}>
            <div className={"row"}>
                <div className={"col-10"}>
                    <GenericHeader
                        icon={faTable}
                        title={"Statistic"}
                        meta={false}
                        size={null}
                    />
                </div>
                <div className={"col-2 text-end"}>
                    <Button outline disabled={loading}
                            color={"primary"}
                            className={"rounded-0"}
                            onClick={fetchData}
                    >
                        <FontAwesomeIcon icon="sync" spin={loading}/>
                        {"  "} Refresh
                    </Button>
                </div>
                <hr className="mt-3 mb-2"/>
                <div className={"col-12"}>
                    <div className="mb-2">
                        <p className="h2">Translation progress statistic:</p>
                        {
                            translationStats.map((trans, index) =>
                                <div className={"mt-2"} key={index}>
                                    <label className="form-label">
                                        {
                                            trans.groupBy === 'all_lan_code' && <>
												All language:
											</>
                                        }

                                        {
                                            trans.groupBy !== 'all_lan_code' && <>
												<LangWithFlag code={trans.groupBy}/>
											</>
                                        }
                                    </label>
                                    <div className="progress rounded-0" style={{"height": "1.85em"}}>
                                        <div
                                            className={`progress-bar bg-primary progress-bar-animated progress-bar-striped`}
                                            role="progressbar"
                                            style={{"width": `${calculatePercentage(trans.totalTranslated, trans.totalUntranslated)}%`}}>
                                            {`${calculatePercentage(trans.totalTranslated, trans.totalUntranslated)}% Translated`}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className={"col-12 mt-3"}>
                    <Bar options={chartOption} data={activitiesChart}/>
                </div>
            </div>
        </div>
    )
};


export default StatsDashBoard;
