import axios from 'axios';
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {createEntitySlice, EntityState, IQueryParams, serializeAxiosError} from 'app/shared/reducers/reducer.utils';
import {defaultValue, IApplication} from 'app/shared/model/application.model';

const initialState: EntityState<IApplication> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    updateSuccess: false,
};

const apiUrl = 'api/applications';

export const getEntities = createAsyncThunk('applications/fetch_entity_list', async ({
                                                                                         page,
                                                                                         size,
                                                                                         sort
                                                                                     }: IQueryParams) => {
    const requestUrl = `${apiUrl}`;
    return axios.get<IApplication[]>(requestUrl);
});

export const createEntity = createAsyncThunk(
    'applications/create_entity',
    async (entity: IApplication, thunkAPI) => {
        const result = await axios.post<IApplication>(apiUrl, cleanEntity(entity));
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);

export const updateEntity = createAsyncThunk(
    'applications/update_entity',
    async (entity: IApplication, thunkAPI) => {
        const result = await axios.put<IApplication>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);


export const partialUpdateEntity = createAsyncThunk(
    'applications/partial_update_entity',
    async (entity: IApplication, thunkAPI) => {
        const result = await axios.patch<IApplication>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);

export const deleteEntity = createAsyncThunk(
    'applications/delete_entity',
    async (id: string | number, thunkAPI) => {
        const requestUrl = `${apiUrl}/${id}`;
        const result = await axios.delete<IApplication>(requestUrl);
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);


// slice

export const ApplicationSlice = createEntitySlice({
    name: 'application',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(deleteEntity.fulfilled, state => {
                state.updating = false;
                state.updateSuccess = true;
                state.entity = {};
            })
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const {data} = action.payload;

                return {
                    ...state,
                    loading: false,
                    entities: data,
                };
            })
            .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
                state.updating = false;
                state.loading = false;
                state.updateSuccess = true;
                state.entity = action.payload.data;
            })
            .addMatcher(isPending(getEntities), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.updating = true;
            });
    },
});

export const {reset} = ApplicationSlice.actions;

// Reducer
export default ApplicationSlice.reducer;
