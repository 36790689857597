import React, {useEffect, useMemo, useRef, useState} from 'react'
import Select from "react-select";
import {toast} from "react-toastify";
import {exportByLanguage, exportTextCode} from "app/shared/util/export-utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Flags from "country-flag-icons/react/3x2";
import {getCountryCode} from "app/shared/util/string-utils";
import GenericHeader from "app/shared/component/generic-manage-header";
import {faFileExport} from "@fortawesome/free-solid-svg-icons";
import {FormGroup, Input, Label} from 'reactstrap';
import {useAppDispatch, useAppSelector} from "app/config/store";
import {getEntities as appGetEntities} from "app/entities/application/application.reducer";
import {getEntities as categoryGetEntities} from "app/entities/category/category.reducer";
import {ITextContentExportFilterCriteria} from "app/shared/model/text-content.model";

const LANGUAGE = ({data, onClose, ...props}) => {

    const selectRef = useRef(null);
    const [check, setCheck] = useState({
        untranslated: false,
        multi: false
    });
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const options = useMemo(() => {
        return data.map(eachOption => {
            const Flag = Flags[(getCountryCode(eachOption.langCode))];
            return {
                ...eachOption,
                value: eachOption.langCode,
                label: <>{Flag !== undefined && <Flag style={{"height": "14px"}}/>}
                    <code className={"ms-3"}>{`${eachOption.langCode}`}</code>
                </>,
            };
        });

    }, data);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (selectRef.current.getValue().length === 0) {
            toast.warning("No language selected");
            setLoading(false);
        } else {
            const languageChoices = selectRef.current.getValue().map(val => {
                return {id: val.id, langCode: val.langCode}
            });
            exportByLanguage(languageChoices, check.untranslated, check.multi)
                .then(() => toast.success("Export completed !"))
                .catch(e => toast.error(e.message))
                .finally(() => setLoading(false));
        }
    }

    const onSelect = (option: any) => {
        setIsDisabled(option.length < 2)
    }

    // @ts-nocheck
    return (
        <>
            <GenericHeader icon={faFileExport}
                           title={"Exports by languages"}
                           size={0}
                           meta={false}
            />
            <hr className="mt-3 mb-2"/>

            <form className="row" onSubmit={onSubmit}>
                <div className="col-md-12">
                    <label htmlFor="id" className="col-form-label">Select Language:</label>
                    <Select
                        isMulti
                        isLoading={loading}
                        isSearchable={true}
                        options={options}
                        className={"basic-multi-select w-100"}
                        placeholder={"Select languages ..."}
                        classNamePrefix={"select"}
                        maxMenuHeight={80}
                        ref={selectRef}
                        onChange={onSelect}
                    />
                </div>

                <div className={"col-3 mt-4"}>
                    <span className="col-3">Export Options:</span>
                </div>

                <div className={"col-9 mt-4"}>
                    <FormGroup check>
                        <Input type="checkbox"
                               checked={check.untranslated}
                               onChange={() => setCheck({...check, untranslated: !check.untranslated})}
                        />
                        <Label check>Include untranslated</Label>
                    </FormGroup>

                    <FormGroup check>
                        <Input type="checkbox"
                               checked={check.multi}
                               disabled={isDisabled}
                               onChange={() => setCheck({...check, multi: !check.multi})}
                        />
                        <Label check>Export to multiple files</Label>
                    </FormGroup>

                </div>

                <div className={"col-12 mt-3 text-start"}>
                    <button type="submit"
                            className="btn btn-primary"
                    >
                        {!loading && "Export"}
                        {loading && <><FontAwesomeIcon icon="sync" spin={true}/> Processing... </>}
                    </button>
                </div>
            </form>
        </>
    );
};

const CODE = ({onClose, ...props}) => {

    const dispatch = useAppDispatch();
    const applicationList = useAppSelector(state => state.application.entities);
    const categoryList = useAppSelector(state => state.category.entities);

    // STATE
    const [filter, setFilter] = useState({
        application: null,
        category: null,
        includeCodeID: false
    });
    const [loading, setLoading] = useState(false);

    // REF
    const categoryOptions = filter.application?.categories ?? [];

    // HOOKS
    useEffect(() => {
        dispatch(appGetEntities({}));
        dispatch(categoryGetEntities({}));
    }, []);

    const onSelectApplication = (event) => {
        const selectAppID = event.target.value;
        const selectApplication = applicationList.find(app => app.id == selectAppID)
        setFilter({
            ...filter,
            application: selectApplication,
            category: null
        })
    }

    const onSelectCategory = (event) => {
        const selectCategoryID = event.target.value;
        const selectCategory = categoryList.find(c => c.id == selectCategoryID)
        setFilter({
            ...filter,
            category: selectCategory
        })
    }

    const onClear = (e) => {
        e.preventDefault()
        setFilter({
            application: null,
            category: null,
            includeCodeID: false
        })
    };

    const onsubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const filterParam: ITextContentExportFilterCriteria = {
            includeCodeID: filter.includeCodeID,
            applicationId: !!filter.application ? filter.application.id : null,
            categoryId: !!filter.category ? filter.category.id : null
        }
        exportTextCode(filterParam)
            .then(() => toast.success("Export completed !"))
            .catch(e => toast.error(e.message))
            .finally(() => setLoading(false));
    }

    return (
        <>
            <GenericHeader icon={faFileExport}
                           title={"Exports text code"}
                           size={0}
                           meta={false}
            />
            <hr className="mt-3 mb-2"/>

            <form className="row" onSubmit={onsubmit}>
                <div className={"col-12"}>
                    <Label>Filter criteria:</Label>
                </div>
                <div className="col-5">
                    <div className="input-group">
                        <div className="input-group-text rounded-0">Application</div>
                        <select className="form-select rounded-0"
                                aria-label="Select application"
                                onChange={onSelectApplication}
                        >
                            <option value="" selected={filter.application == null} disabled hidden>Select application
                            </option>
                            {
                                applicationList.map((app, index) => <option
                                    value={app.id}
                                    key={index}
                                >
                                    {app.name}
                                </option>)
                            }
                        </select>
                    </div>
                </div>
                <div className="col-5">
                    <div className="input-group">
                        <div className="input-group-text rounded-0">Category</div>
                        <select className="form-select rounded-0"
                                aria-label="Select category"
                                disabled={filter.application == null || categoryOptions.length == 0}
                                onChange={onSelectCategory}>
                            <option value="" selected={filter.category == null} key={null} hidden>
                                Select category
                            </option>
                            {
                                categoryOptions.map((category, index) => <option
                                    value={category.id}
                                    key={index}
                                >
                                    {category.name}
                                </option>)
                            }
                        </select>
                    </div>
                </div>
                <div className="col-2 d-flex align-items-end">
                    <button className="w-100 btn btn-secondary align-bottom rounded-0"
                            onClick={(e) => onClear(e)}>
                        Clear
                    </button>
                </div>
                <div className={"col-12 mt-3 text-start"}>
                    <Input type="checkbox"
                           checked={filter.includeCodeID}
                           onChange={() => setFilter({...filter, includeCodeID: !filter.includeCodeID})}
                    />
                    <Label check className={"ms-2"}>Include code ID in export file</Label>
                </div>

                <div className={"col-12 mt-3 text-start"}>
                    <button
                        type={"submit"}
                        disabled={loading}
                        className="btn btn-primary">
                        Export
                    </button>
                </div>
            </form>
        </>
    );
};


export const TextContentExport = {
    LANGUAGE: LANGUAGE,
    CODE: CODE
};

export default TextContentExport;
