import React, {useState} from 'react';

import {useAppDispatch} from 'app/config/store';
import GenericHeader from "app/shared/component/generic-manage-header";
import {faSquareEnvelope} from "@fortawesome/free-solid-svg-icons";
import {notifyAllAdminFirstTimeLogin} from "app/shared/util/invite-utils";
import {toast} from "react-toastify";
import {Spinner} from "reactstrap";

export const InvitationForm = () => {
    const dispatch = useAppDispatch();

    const defaultForm = {
        submitButtonDisable: true,
        reason: "",
        userName: ""
    }

    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState(defaultForm)

    const onReasonType = (event) => {
        setForm({...form, reason: event.target.value})
    }

    const onUserName = (event) => {
        setForm({...form, userName: event.target.value})
    }

    const onSubmit = (event) => {
        event.preventDefault()
        setLoading(true)

        notifyAllAdminFirstTimeLogin(form)
            .then((res) => toast.success(res.data.msg))
            .catch(err => {
                toast.error(err.response.data.msg)
            })
            .finally(() => setLoading(false))
    }

    return (
        <div className={"container-fluid"}>
            <div className={"row"}>
                <div className={"col-6"}>
                    <GenericHeader
                        icon={faSquareEnvelope}
                        title={"Invitation form"}
                        size={0}
                        meta={false}
                    />
                    <hr className="mt-3 mb-2"/>
                    <form className={"row g-3"} onSubmit={onSubmit}>
                        <div className="mb-1">
                            <label className="form-label">Your name</label>
                            <input value={form.userName}
                                   type="text"
                                   className="form-control"
                                   placeholder="Your name"
                                   onChange={onUserName}
                                   required
                                   autoComplete={"off"}
                                   maxLength={30}/>
                        </div>
                        <div className="mb-1">
                            <label htmlFor="reason" className="form-label">Reason to access this system:</label>
                            <textarea value={form.reason}
                                      className="form-control"
                                      id="reason"
                                      rows={8}
                                      onChange={onReasonType}
                                      required
                                      maxLength={300}
                                      placeholder={"Write a short description on why you need to access this"}
                            />
                        </div>
                        <button type={"submit"}
                                className={"btn btn-primary w-25 rounded-0"}
                                disabled={loading}>
                            Submit {" "}{loading && <Spinner size={"sm"}/>}
                        </button>
                    </form>
                </div>
                <div className={"col-6 text-end"}>
                    <img src="content/images/invitation-form-bg.jpg" alt="Form-bg" className={"w-25"}/>
                </div>
            </div>
        </div>
    )
};

export default InvitationForm;
