import React from "react";
import {Badge} from "reactstrap";

const color = [
  "primary",
  "secondary",
  "success",
  "danger",
  "info"
]

export const BadgeArray = ({data}) => {
  return (
      <span className={'text-truncate w-100'}>
        {
          <Badge key={data}
                 color={color[data.length % color.length]}
                 className={"me-1 rounded-0"}>{data}
          </Badge>
        }
      </span>
  );
};

export default BadgeArray;
