import {useEffect} from 'react';

function useEscKeyDown(condition: boolean, action: any) {
    useEffect(() => {
        const handleEsc = (event: any) => {
            if (event.key === 'Escape' || event.keyCode === 27 && condition) {
                action()
            }
        };
        window.addEventListener('keydown', handleEsc, true);
        return () => {
            window.removeEventListener('keydown', handleEsc, true);
        };
    }, [condition, action]);
}

export default useEscKeyDown;