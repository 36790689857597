import React, {useEffect, useState} from 'react';
import GenericHeader from "app/shared/component/generic-manage-header";
import {faLaptop} from "@fortawesome/free-solid-svg-icons";
import {IApplication} from "app/shared/model/application.model";
import {useAppDispatch, useAppSelector} from "app/config/store";
import {ICategory} from "app/shared/model/category.model";
import {createEntity, partialUpdateEntity} from "app/entities/category/category.reducer";

const prepareAddData = (initialData: ICategory) => {
    return {
        name: initialData.categoryName,
        prefix: initialData.prefix,
        minSubRange: initialData.minSubRange,
        maxSubRange: initialData.maxSubRange,
        applicationId: initialData.applicationId,
        description: initialData.description,
    };
}

const prepareEditData = (initialData: ICategory) => {
    return {
        id: initialData.categoryId,
        name: initialData.categoryName,
        prefix: initialData.prefix,
        minSubRange: initialData.minSubRange,
        maxSubRange: initialData.maxSubRange,
        applicationId: initialData.applicationId,
        description: initialData.description,
    };
}

const prepareAvailableRange = (selectApp: IApplication) => {
    if (selectApp.categories.length == 0) {
        return selectApp.minRange
    }

    return (1 + selectApp.categories.reduce((max, currentValue) => {
        return currentValue.maxSubRange > max ? currentValue.maxSubRange : max
    }, -Infinity))
}

const CategoryForm = (
    {category = {}, isEdit = false, applicationList, reload, onClose}: {
        category: ICategory,
        isEdit: boolean,
        applicationList: IApplication[],
        reload: any,
        onClose: any
    }
) => {

    const dispatch = useAppDispatch();
    const [formCategory, setFormCategory] = useState<ICategory>(category);
    const [selectApp, setSelectApp] = useState<IApplication>(null)
    const [availableRange, setAvailableRange] = useState<number>(0)
    const updateSuccess = useAppSelector(state => state.category.updateSuccess);

    const onSelectApplication = (event) => {
        const selectAppID = event.target.value;
        const application = applicationList.find(app => app.id == selectAppID)
        setSelectApp(application)
        setAvailableRange(prepareAvailableRange(application))
        setFormCategory({
            ...formCategory,
            applicationId: application.id
        })
    }

    useEffect(() => {
        if (updateSuccess) onClose()
    }, [updateSuccess]);

    useEffect(() => {
        if (isEdit) {
            const application = applicationList.find(app => app.id == category.applicationId)
            setSelectApp(application)
            setAvailableRange(prepareAvailableRange(application))
        }
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault()
        if (isEdit) {
            await dispatch(partialUpdateEntity(prepareEditData(formCategory)))
            reload()
            return
        }

        await dispatch(createEntity(prepareAddData(formCategory)))
        reload()
    }

    return (
        <>
            <GenericHeader
                icon={faLaptop}
                title={`${isEdit ? "Edit" : "Add"} Category`}
                size={0}
                meta={false}
            />

            <hr className="mt-3 mb-2"/>
            <form onSubmit={onSubmit}>
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className="col-6">
                            <label htmlFor="name"
                                   className="form-label">
                                Name <span className={"text-danger"}>*</span>
                            </label>
                            <input type="text"
                                   className={"form-control form-control-sm"}
                                   onChange={
                                       e => setFormCategory({
                                           ...formCategory,
                                           categoryName: e.currentTarget.value
                                       })
                                   }
                                   required
                                   value={!!formCategory.categoryName ? formCategory.categoryName : ""}
                                   id="name"/>
                        </div>
                        <div className="col-6">
                            <label htmlFor="prefix"
                                   className="form-label">Prefix <span className={"text-danger"}>*</span>
                            </label>
                            <input type="text"
                                   className={"form-control form-control-sm"}
                                   id="prefix"
                                   onChange={
                                       e => setFormCategory({
                                           ...formCategory,
                                           prefix: e.currentTarget.value
                                       })
                                   }
                                   value={!!formCategory.prefix ? formCategory.prefix : ""}
                                   required
                            />
                        </div>
                        <div className="col-6 mt-1">
                            <label htmlFor="minSubRange"
                                   className="form-label">
                                Min Sub Range <span className={"text-danger"}>*</span>
                            </label>
                            <input type="number"
                                   className={"form-control form-control-sm"}
                                   onChange={
                                       e => setFormCategory({
                                           ...formCategory,
                                           minSubRange: parseInt(e.currentTarget.value)
                                       })
                                   }
                                   value={(formCategory.minSubRange != null) ? formCategory.minSubRange : null}
                                   disabled={isEdit}
                                   id="minSubRange"/>
                        </div>
                        <div className="col-6 mt-1">
                            <label htmlFor="maxSubRange"
                                   className="form-label">
                                Max Sub Range <span className={"text-danger"}>*</span>
                            </label>
                            <input type="number"
                                   className={"form-control form-control-sm"}
                                   onChange={
                                       e => setFormCategory({
                                           ...formCategory,
                                           maxSubRange: parseInt(e.currentTarget.value)
                                       })
                                   }
                                   value={(formCategory.maxSubRange != null) ? formCategory.maxSubRange : null}
                                   disabled={isEdit}
                                   id="maxSubRange"/>
                        </div>
                        <div className="col-12 mt-1">
                            <label htmlFor="application"
                                   className="form-label">
                                Application <span className={"text-danger"}>*</span>
                            </label>
                            <select className="form-select"
                                    aria-label="Select application"
                                    onChange={onSelectApplication}
                                    required
                            >
                                <option value="" selected={!isEdit} disabled hidden>Please choose application</option>
                                {
                                    applicationList.map(app => <option
                                        value={app.id}
                                        key={app.id}
                                        selected={isEdit && app.id == category.applicationId}
                                    >
                                        {app.name}
                                    </option>)
                                }
                            </select>
                        </div>
                        <div className="col-12 mt-1">
                            <label htmlFor="description"
                                   className="form-label">
                                Description
                            </label>
                            <textarea
                                className="form-control"
                                id="description" rows={3}
                                onChange={
                                    e => setFormCategory({
                                        ...formCategory,
                                        description: e.currentTarget.value
                                    })
                                }
                                value={!!formCategory.description ? formCategory.description : ""}
                            >
                            </textarea>
                        </div>
                        {
                            !!selectApp &&
							<p className={"text-success mt-1"}>
                                {`Application range from ${selectApp.minRange} to ${selectApp.maxRange}, prefix `}
								<span
									className={"fw-bold"}>{`${selectApp.prefix}`}
                                </span>
                                {` available sub range ${availableRange}-${selectApp.maxRange}`}
							</p>
                        }
                        <button type="submit" className="btn btn-primary mt-1 rounded-0">Submit</button>
                    </div>
                </div>
            </form>
        </>
    );
};
export default CategoryForm;

