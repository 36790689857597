import React from 'react';
import {faCheck, faXmarkCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ActiveDisplay = ({value}) => {
  if (value) {
    return <FontAwesomeIcon icon={faCheck} className={"text-success"} />
  } else {
    return <FontAwesomeIcon icon={faXmarkCircle} className={"text-danger"} />
  }
};

export default ActiveDisplay;
