import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {faChartSimple, faFileExport, faPlusSquare} from '@fortawesome/free-solid-svg-icons'
import {Dropdown} from 'react-bootstrap';
import {useAppSelector} from 'app/config/store';
import {AUTHORITIES} from 'app/config/constants';
import {hasAnyAuthority} from 'app/shared/auth/private-route';
import {Button} from "reactstrap";

export const TextContentPanel = ({refresh, loading, handleModal, buttonAction}) => {

    const isDeveloper = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.DEVELOPER]));

    // @ts-ignore
    return (
        <div className={"text-end"}>
            <Button onClick={() => handleModal(true, buttonAction.STATS_VIEW)}
                    disabled={loading}
                    color={"success"}
                    className={"rounded-0 me-2"}>
                <FontAwesomeIcon icon={faChartSimple}/>
                {"  "} Statistic
            </Button>

            <Button onClick={() => handleModal(true, buttonAction.ADD_TEXT_CODE)}
                    disabled={loading}
                    color={"success"}
                    className={"rounded-0 me-2"}>
                <FontAwesomeIcon icon={faPlusSquare}/>
                {"  "} Add
            </Button>

            <Dropdown className={"d-inline"}>
                <Dropdown.Toggle className={"rounded-0 me-2 d-inline"}>
                    <FontAwesomeIcon icon={faFileExport}/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleModal(true, buttonAction.EXPORT_BY_APP)}>Export all text
                        code</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleModal(true, buttonAction.EXPORT_BY_LANGUAGE)}>Export by
                        language</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Button outline onClick={refresh} disabled={loading} color={"primary"} className={"rounded-0"}>
                <FontAwesomeIcon icon="sync" spin={loading}/>
                {"  "} Refresh
            </Button>
        </div>
    );
};

export default TextContentPanel;
