export const baseModalStyles = {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -45%)',
    width: '65%',
    maxHeight: '85%',
    zIndex: 1
};