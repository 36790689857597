import React, {useState} from 'react';
import ReactPaginate from "react-paginate";
import {PageInfo} from "app/shared/model/rest.model";
import {Button, Input, InputGroup} from 'reactstrap';
import {Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsAltV} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";


interface CustomPaginationProps {
    pageInfo: PageInfo,
    onPageChanged: any,
    pageRangeDisplayed?: number,
    gotoProportion?: string,
    pagingProportion?: string,
}

const CustomPagination = ({
                              pageInfo,
                              onPageChanged,
                              pageRangeDisplayed = 4,
                              gotoProportion = "3",
                              pagingProportion = "9"
                          }: CustomPaginationProps) => {
    if (!pageInfo) return <></>

    const [inputPage, setInputPage] = useState("")
    const handlePageClick = (event) => {
        onPageChanged(event.selected)
    };
    const handleGotoPage = () => {
        const page = Number(inputPage)
        if (page < 0 || page > pageInfo.totalPages) {
            toast.error("Invalid page number")
            setInputPage("")
            return
        }
        const offSetPage = page - 1
        setInputPage("")
        onPageChanged(offSetPage)
    };

    return (
        <div className={"container-fluid"}>
            <div className={"row "}>
                <div className={`col-${gotoProportion} p-0`}>
                    <InputGroup>
                        <Button className={"rounded-0"} color={"primary"} onClick={handleGotoPage}
                                disabled={inputPage === ""}>Go to</Button>
                        <Input value={inputPage} onChange={event => setInputPage(event.target.value)}/>
                        <Dropdown className={"d-inline"}>
                            <Dropdown.Toggle className={"rounded-0 d-inline"}>
                                <FontAwesomeIcon icon={faArrowsAltV}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {
                                    onPageChanged(0)
                                }}>Go the first</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    onPageChanged(pageInfo.totalPages - 1)
                                }}>Go to last</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </InputGroup>
                </div>
                <div className={`col-${pagingProportion} align-item-end`}>
                    <ReactPaginate
                        forcePage={pageInfo.pageNumber}
                        nextLabel="Next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={pageRangeDisplayed}
                        marginPagesDisplayed={0}
                        pageCount={pageInfo.totalPages}
                        previousLabel="< Prev"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        </div>
    );
};

export default CustomPagination;
