import React, {useEffect, useState} from 'react'
import GenericHeader from "app/shared/component/generic-manage-header";
import {faArrowsRotate, faCheck, faCross, faFaceSurprise, faUserGear} from "@fortawesome/free-solid-svg-icons";
import {doRevert, getHistory, HistoryRes, RevertRes, ReviewRes, reviewRevert} from "app/shared/util/revert-utils";
import {Nav, NavItem, NavLink, Spinner, TabContent, TabPane} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GenericManageTable from "app/shared/component/generic-manage-table";
import {convertDateTimeFromServer} from "app/shared/util/date-utils";
import LangWithFlag from "app/shared/component/lang-with-flag";
import ActiveDisplay from "app/shared/component/active-display";

const TabName = {
    REVIEW: "REVERT",
    HISTORY: "HISTORY",
}

export const TextContentRevert = ({onClose}) => {

    const [review, setReview] = useState<ReviewRes>(null);
    const [revert, setRevert] = useState<RevertRes>(null);
    const [history, setHistory] = useState<HistoryRes[]>(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(TabName.REVIEW);


    const colDefReview: any = [
        {
            field: 'before.transString',
            headerName: 'Before',
            cellStyle: {textAlign: 'left'},
            flex: 1
        },
        {
            field: 'after.transString',
            headerName: 'After',
            cellStyle: {textAlign: 'left'},
            flex: 1
        }
    ];

    const colDefHistory: any = [
        {
            field: 'editor',
            headerName: 'Editor',
            cellStyle: {textAlign: 'left'},
            flex: 1
        },
        {
            field: 'language',
            headerName: 'Language',
            cellStyle: {textAlign: 'left'},
            flex: 1,
            cellRenderer: (params: any) => <LangWithFlag code={params.value}/>
        },
        {
            field: 'active',
            headerName: 'Revertible',
            cellRenderer: (params: any) => {
                return <ActiveDisplay value={params.value}/>
            }
        },
        {
            field: 'startTime',
            headerName: 'Timestamp',
            cellRenderer: (params: any) => {
                return <b> {convertDateTimeFromServer(params.value)} </b>
            }
        }
    ];

    const fetchData = async () => {
        let reviewRes = await reviewRevert();
        let historyRes = await getHistory();
        return [reviewRes.data, historyRes.data];
    }

    useEffect(() => {
        setLoading(true);
        fetchData()
            .then(data => {
                // @ts-ignore
                setReview(data[0]);
                // @ts-ignore
                setHistory(data[1]);
            })
            .then(() => setLoading(false));
    }, [])

    const onClick = async (e) => {
        e.preventDefault();
        setLoading(true);

        let revertRes = await doRevert(review.data);
        setRevert(revertRes.data)

        let reviewRes = await reviewRevert();
        setReview(reviewRes.data)

        let historyRes = await getHistory();
        setHistory(historyRes.data);

        setLoading(false);
    }

    if (loading) {
        return (
            <>
                <GenericHeader icon={faArrowsRotate}
                               title={"Revert files"}
                               size={0}
                               meta={false}
                />
                <hr className="mt-3 mb-2"/>
                <div className="col-md-12">
                    <Spinner/>
                </div>
            </>
        );
    } else {

        return (
            <>
                <GenericHeader icon={faArrowsRotate}
                               title={"Revert import"}
                               size={0}
                               meta={false}
                />
                <hr className="mt-3 mb-2"/>

                {
                    revert != null && <>
						<div className={`alert alert-${revert.success ? 'success' : 'danger'} alert-dismissible`}
							 role="alert">
                            {revert.success && <FontAwesomeIcon icon={faCheck}/>}
                            {!revert.success && <FontAwesomeIcon icon={faCross}/>}
                            {" "}{revert.message}
						</div>
					</>
                }

                <div className="col-md-12">

                    {
                        review.revertible && <>
							<div className="card">
								<div className="card-header">
									<FontAwesomeIcon icon={faUserGear}/> {" "} <b>Editor:</b> {review.editor}
								</div>
								<ul className="list-group list-group-flush">
									<li className="list-group-item"><b>Language:</b> <LangWithFlag code={review.language}/>
									</li>
									<li className="list-group-item">
										<b>Timestamp:</b> {convertDateTimeFromServer(review.timeStamp)}</li>
								</ul>
							</div>
						</>}


                    <Nav tabs className={"mb-3 mt-3"}>
                        <NavItem>
                            <NavLink className={activeTab == TabName.REVIEW ? 'active' : ''}
                                     onClick={() => setActiveTab(TabName.REVIEW)}>
                                Review
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab == TabName.HISTORY ? 'active' : ''}
                                     onClick={() => setActiveTab(TabName.HISTORY)}>
                                Import history
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={TabName.REVIEW}>
                            {
                                !review.revertible && <>
									<div className={`alert alert-warning`} role="alert">
										<FontAwesomeIcon icon={faFaceSurprise}/> {" "} {review.message}
									</div>
								</>
                            }

                            {
                                review.revertible && <>
									<div className={'container'}>
										<div className={'row'}>
											<div className={'col-12'}>
												<div className={`alert alert-primary`} role="alert">
													Previous import have make these changes, do you want to revert them ?
													<button className={'btn btn-sm btn-primary ms-2'}
															onClick={onClick}>
														Revert
													</button>
												</div>
											</div>
											<div className={'col-12'}>
												<GenericManageTable
													colDef={colDefReview}
													data={review.data}
												/>
											</div>
										</div>
									</div>
								</>
                            }

                        </TabPane>
                        <TabPane tabId={TabName.HISTORY}>
                            <GenericManageTable
                                colDef={colDefHistory}
                                data={history}
                            />
                        </TabPane>
                    </TabContent>
                </div>
            </>
        );

    }

};

export default TextContentRevert;
