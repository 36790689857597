import ErrorBoundaryRoutes from "app/shared/error/error-boundary-routes";
import {Route} from "react-router-dom";
import React from "react";
import ChangeLogManage from "app/entities/change_log/change-log-manage";

const ChangeLogRoutes = () => (
    <ErrorBoundaryRoutes>
        <Route index element={<ChangeLogManage/>}/>
    </ErrorBoundaryRoutes>
);

export default ChangeLogRoutes;
