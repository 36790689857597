import React, {useEffect, useMemo, useRef, useState} from 'react';
import Modal from 'react-modal';
import {useAppDispatch, useAppSelector} from 'app/config/store';
import {getEntities as textContentGetEntities} from '../text-content/text-content.reducer';
import {getActiveEntities as lanContentGetEntities} from '../language/language.reducer';
import {partialUpdateEntity as transPartialUpdateEntity} from './translation.reducer';

import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import Select, {MultiValue} from 'react-select';
import TextContentCell from 'app/entities/text-content/text-content-cell';
import useModals from 'app/hooks/useModals';
import TextContentItem from 'app/entities/text-content/text-content-item';
import TextContentExport from 'app/entities/text-content/text-content-export';
import LangWithFlag from "app/shared/component/lang-with-flag";
import ApplicationCell from "app/entities/application/application-cell";
import TextContentPanel from "app/entities/text-content/text-content-panel";
import CloseButton from 'react-bootstrap/CloseButton';
import GenericHeader from "app/shared/component/generic-manage-header";
import {faLanguage} from "@fortawesome/free-solid-svg-icons";
import {ICellRendererParams} from 'ag-grid-community';
import TextContentAdd from "app/entities/text-content/text-content-add";
import {PageRequest} from "app/shared/model/rest.model";
import CustomPagination from "app/shared/component/custom-pagination";
import {useLocation, useNavigate} from "react-router-dom";
import {extractPageRequest} from "app/shared/util/entity-utils";
import CustomSortOrderSelection from "app/shared/component/custom-sort-order-selection";
import useEscKeyDown from "app/hooks/useEscKeyDown";
import TextContentSearch from "app/entities/text-content/text-content-search";
import CopyableComponent from "app/shared/component/copy-to-clipboard";
import {baseModalStyles} from "app/shared/styles/modal-styles";
import StatsDashBoard from "app/entities/statistics";

const agGridContainerStyle = {
    width: '100%'
};

const modalStyles = {
    EDIT_TEXT_CODE: {
        content: {
            ...baseModalStyles,
            width: '65%',
        },
    },
    EXPORT_BY_LANGUAGE: {
        content: {
            ...baseModalStyles,
            width: '40%',
        },
    },
    EXPORT_BY_APP: {
        content: {
            ...baseModalStyles,
            width: '50%',
        },
    },
    ADD_TEXT_CODE: {
        content: {
            ...baseModalStyles,
            width: '30%',
        },
    },
    STATS_VIEW: {
        content: {
            ...baseModalStyles,
            width: '95%',
        },
    },
};

const buttonAction = {
    EDIT_TEXT_CODE: 'EDIT_TEXT_CODE',
    EXPORT_BY_LANGUAGE: 'EXPORT_BY_LANGUAGE',
    EXPORT_BY_APP: 'EXPORT_BY_APP',
    ADD_TEXT_CODE: 'ADD_TEXT_CODE',
    STATS_VIEW: 'STATS_VIEW',
};

export const TranslationManage = () => {

    // CONST
    const baseColDef: any = [
        {
            field: 'rangeId',
            headerName: 'Code ID',
            cellStyle: {textAlign: 'left'},
            maxWidth: 120,
            pinned: 'left',
            suppressMenu: true,
        },
        {
            field: 'code',
            headerName: 'Code',
            // filter: CustomFilter,
            cellStyle: {textAlign: 'left'},
            pinned: 'left',
            minWidth: 300,
            filter: false,
            suppressMenu: true,
            floatingFilter: false,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params: any) => {
                return (
                    <CopyableComponent
                        children={<span
                            className={'text-truncate text-left w-100 text-primary clickable'}
                            onClick={() =>
                                handleModal(true, buttonAction.EDIT_TEXT_CODE, {
                                    textContent: params.data,
                                })
                            }
                        > {params.value} </span>} text={params.value}
                    />
                );
            },
        },
        {
            field: 'applicationName',
            headerName: 'Application',
            filter: false,
            floatingFilter: false,
            suppressMenu: true,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellStyle: {textAlign: 'left'},
            pinned: 'left',
            maxWidth: 150,
            resizable: true,
            wrapText: true,
            editable: false,
            cellEditorPopup: true,
            // autoHeight: true,
            cellRenderer(params: any) {
                return <ApplicationCell data={params.value}/>
            },
        },
        {
            field: 'categoryName',
            headerName: 'Category',
            filter: false,
            floatingFilter: false,
            suppressMenu: true,
            cellStyle: {textAlign: 'left'},
            pinned: 'left',
            maxWidth: 170,
            resizable: true,
            wrapText: true,
            editable: false,
            cellEditorPopup: true,
            // autoHeight: true,
            cellRenderer(params: any) {
                return <ApplicationCell data={params.value}/>
            },
        },
    ];


    // REF
    const gridRef = useRef(null);
    const selectRef = useRef(null);

    // HOOKS
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const {open: openModal, data: dataModal, context: contextModal, handleModal} = useModals();
    useEscKeyDown(openModal, () => handleModal(false, 'Closing modal ...'))

    const [pageRequest, setPageRequest] = useState<PageRequest>(extractPageRequest({
        page: 0,
        size: 15,
        sort: "id",
        order: "DESC",
        query: null
    }, location.search));

    // REDUX STATE
    const textContentList = useAppSelector(state => state.textContent.entities);
    const textLoading = useAppSelector(state => state.textContent.loading);
    const textContentPageInfo = useAppSelector(state => state.textContent.pageInfo)

    const lanLoading = useAppSelector(state => state.language.loading);
    const langList = useAppSelector(state => state.language.entities);
    const [languageOptions, setLanguageOptions]: any = useState([]);

    // STATE
    const [colDefs, setColDefs] = useState<{}[]>(baseColDef);

    const defaultColDef = useMemo(
        () => ({
            sortable: false,
            resizable: true,
            filter: true,
            minWidth: 200,
        }),
        []
    );

    // RENDER HOOKS
    useEffect(() => {
        const buildLanguageOptions = langList.map(data => {
            return {
                ...data,
                value: data.langCode,
                label: <LangWithFlag code={data.langCode}/>,
            };
        });
        setLanguageOptions(buildLanguageOptions);
        // Set english as default display
        if (selectRef.current.getValue().length === 0) {
            selectRef.current.setValue(buildLanguageOptions.filter(i => i.langCode === 'en_US'));
        }
    }, [lanLoading]);

    useEffect(() => {
        getAllEntities();
        let endURL = `?page=${pageRequest.page}&sort=${pageRequest.sort}&order=${pageRequest.order}`;
        if (!!pageRequest.query) endURL = endURL + `&query=${pageRequest.query}`;
        navigate(`${location.pathname}${endURL}`);
    }, [pageRequest.page, pageRequest.sort, pageRequest.order, pageRequest.query]);

    // FETCH QUERY
    const getAllEntities = () => {
        dispatch(
            textContentGetEntities({
                page: pageRequest.page,
                size: pageRequest.size,
                sort: `${pageRequest.sort},${pageRequest.order}`,
                query: pageRequest.query
            })
        );
        dispatch(lanContentGetEntities({}));
    };

    const getAllEntitiesFromURL = async () => {
        // After cell click, whole page is render forcing page = 0.
        // Need this code to offset that.
        const urlParams = new URLSearchParams(window.location.search);
        let currentPage = urlParams.get('page');
        let currentSort = urlParams.get('sort');
        let currentOrder = urlParams.get('order');
        let currentQuery = null;

        const urlString = window.location.search.toString()
        const querySectionIdx = urlString.indexOf("&query")
        if (querySectionIdx > 0) {
            currentQuery = urlString.substring(querySectionIdx + "&query=".length, urlString.length)
        }

        const newPageRequest = {
            ...pageRequest,
            page: Number(currentPage),
            sort: currentSort,
            order: currentOrder,
            query: currentQuery
        }
        setPageRequest(newPageRequest)
        dispatch(
            textContentGetEntities({
                page: newPageRequest.page,
                size: newPageRequest.size,
                sort: `${newPageRequest.sort},${newPageRequest.order}`,
                query: newPageRequest.query
            })
        );
        dispatch(lanContentGetEntities({}));
    }

    const onPageSortOrderChange = async (page?: number, sort?: string, order?: string, query?: string) => {
        const newPageRequest = {
            ...pageRequest
        }
        if (page !== null && page !== undefined) newPageRequest["page"] = page
        if (sort !== null && sort !== undefined) newPageRequest["sort"] = sort
        if (order !== null && order !== undefined) newPageRequest["order"] = order
        setPageRequest(newPageRequest)
    }

    const onQueryChange = async (query: string) => {
        const newPageRequest = {
            ...pageRequest,
            page: 0,
            query: query
        }
        setPageRequest(newPageRequest)
    }

    const onSelectLanguage = async (
        newValue: MultiValue<{
            value: string;
            label: string;
            id: number;
            langId: string;
            langName: string;
        }>
    ) => {
        handleSelectLan(newValue);
    };

    const onGridReady = (params: any) => {
        handleSelectLan(selectRef.current.getValue());
        gridRef.current.api.setDomLayout('autoHeight');
    };

    const handleSelectLan = (
        languages: MultiValue<{
            value: string;
            label: string;
            id: number;
            langId: string;
            langName: string;
        }>
    ) => {
        const newColDef = [...baseColDef];
        languages.forEach(newLan => {
            newColDef.push({
                field: `allTranslations.${newLan.value}.transString`,
                headerName: newLan['name'],
                filter: false,
                floatingFilter: false,
                suppressMenu: true,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                flex: 4,
                minWidth: 350,
                editable: params => params.data.allTranslations[`${newLan.value}`] !== undefined,
                onCellValueChanged: event => onEditCell(newLan, event),
                cellRenderer: (params: ICellRendererParams) => (
                    <TextContentCell
                        transString={params.value}
                        textContent={params.data}
                        language={newLan}
                        refresh={getAllEntitiesFromURL}
                    />
                ),
            });
        });
        setColDefs(newColDef);
    };

    const onEditCell = async (language, event) => {
        let newTrans = {};
        if (event.data.allTranslations[language.langCode] !== undefined) {
            const id = event.data.allTranslations[language.langCode].id;
            newTrans = {
                id: id,
                transString: event.newValue,
            };
            await dispatch(transPartialUpdateEntity(newTrans));
        }
        await getAllEntitiesFromURL();
    };

    return (
        <div className={"container-fluid w-100"}>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <GenericHeader
                        icon={faLanguage}
                        size={textContentList.length}
                        title={"Translation Management"}
                        meta={false}
                    />
                </div>
                <div className={"col-md-6 text-left"}>
                    <TextContentPanel
                        refresh={getAllEntitiesFromURL}
                        handleModal={handleModal}
                        loading={textLoading}
                        buttonAction={buttonAction}/>
                </div>
            </div>
            <div className={'row mt-2'}>
                <div className="col-md-12 d-flex justify-content-start align-items-center">
                    <Select
                        isMulti
                        isSearchable={true}
                        options={languageOptions}
                        className={'basic-multi-select w-100'}
                        classNamePrefix="select"
                        onChange={onSelectLanguage}
                        defaultValue={languageOptions[0]}
                        ref={selectRef}
                        isLoading={lanLoading}
                        isDisabled={lanLoading}
                        placeholder={"Select languages ..."}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-12 mt-2'}>
                    <TextContentSearch onSearch={onQueryChange}/>
                </div>
                <div className={"col-12 mt-1 ag-theme-alpine"} style={agGridContainerStyle}>
                    <AgGridReact
                        overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                        overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No data founds</span>'}
                        ref={gridRef as any}
                        rowData={structuredClone(textContentList)}
                        columnDefs={colDefs}
                        defaultColDef={defaultColDef}
                        animateRows={true}
                        rowSelection={'multiple'}
                        onGridReady={onGridReady}
                        enableCellTextSelection={true}
                    />
                </div>
                <div className={"col-6 mt-3"}>
                    <CustomPagination pageInfo={textContentPageInfo} onPageChanged={onPageSortOrderChange}/>
                </div>
                <div className={"col-6 mt-3 text-end"}>
                    <CustomSortOrderSelection onSortOrderChanged={onPageSortOrderChange}
                                              selectableAttributes={[
                                                  {key: "updateTime", label: "Update time"},
                                                  {key: "createTime", label: "Create time"},
                                                  {key: "rangeId", label: "Code ID"}]
                                              }
                    />
                </div>

                {/*FOR MODAL*/}
                <div>
                    <Modal isOpen={openModal}
                           contentLabel="Text Cell Modal"
                           style={modalStyles[dataModal]}
                           closeTimeoutMS={100}
                           ariaHideApp={false}>
                        <div className="text-end text-end float-right d-flex p-1" style={{float: 'right'}}>
                            <CloseButton
                                aria-label="Close"
                                onClick={() => {
                                    handleModal(false, 'Closing modal ...');
                                    getAllEntitiesFromURL();
                                }}
                            />
                        </div>

                        {dataModal === buttonAction.EDIT_TEXT_CODE && (
                            <TextContentItem
                                data={contextModal['textContent']}
                                onCloseModal={() => {
                                    handleModal(false, 'Closing modal ...');
                                    getAllEntitiesFromURL();
                                }}
                            />
                        )}

                        {dataModal === buttonAction.EXPORT_BY_LANGUAGE && (
                            <TextContentExport.LANGUAGE
                                data={languageOptions}
                                onClose={() => {
                                    handleModal(false, 'Closing modal ...');
                                    getAllEntitiesFromURL();
                                }}
                            />
                        )}

                        {dataModal === buttonAction.EXPORT_BY_APP && (
                            <TextContentExport.CODE
                                onClose={() => {
                                    handleModal(false, 'Closing modal ...');
                                    getAllEntitiesFromURL();
                                }}
                            />
                        )}

                        {dataModal === buttonAction.ADD_TEXT_CODE && (
                            <TextContentAdd
                                onClose={() => {
                                    handleModal(false, 'Closing modal ...');
                                    getAllEntitiesFromURL();
                                }}
                            />
                        )}

                        {dataModal === buttonAction.STATS_VIEW && (
                            <StatsDashBoard/>
                        )
                        }
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default TranslationManage;
