import React from 'react';
import {Route} from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import ImportManage from "app/entities/import/import-manage";

const ImportRoutes = () => (
    <ErrorBoundaryRoutes>
        <Route index element={<ImportManage/>}/>
    </ErrorBoundaryRoutes>
);

export default ImportRoutes;
