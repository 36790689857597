import axios from 'axios';
import {createAsyncThunk, isFulfilled, isPending} from '@reduxjs/toolkit';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {createEntitySlice, EntityState, IQueryParams, serializeAxiosError} from 'app/shared/reducers/reducer.utils';
import {defaultValue, ITextContent} from 'app/shared/model/text-content.model';
import PaginationResponse from "app/shared/model/rest.model";

const initialState: EntityState<ITextContent> = {
    loading: false,
    errorMessage: null,
    entities: [],
    entity: defaultValue,
    updating: false,
    totalItems: 0,
    updateSuccess: false,
    pageInfo: null,
};

const apiUrl = 'api/text-contents';

// Actions

export const getEntities = createAsyncThunk('textContent/fetch_entity_list', async ({
                                                                                        query,
                                                                                        page,
                                                                                        size,
                                                                                        sort
                                                                                    }: IQueryParams) => {
    let requestUrl = `${apiUrl}/pageable${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}`;
    if (!!query) requestUrl = requestUrl + query + "&"
    requestUrl = requestUrl + `cacheBuster=${new Date().getTime()}`
    return axios.get<PaginationResponse<ITextContent>>(requestUrl);
});

export const getEntity = createAsyncThunk(
    'textContent/fetch_entity',
    async (id: string | number) => {
        const requestUrl = `${apiUrl}/${id}`;
        return axios.get<ITextContent>(requestUrl);
    },
    {serializeError: serializeAxiosError}
);

export const createEntity = createAsyncThunk(
    'textContent/create_entity',
    async (entity: ITextContent, thunkAPI) => {
        const result = await axios.post<ITextContent>(apiUrl, cleanEntity(entity));
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);

export const updateEntity = createAsyncThunk(
    'textContent/update_entity',
    async (entity: ITextContent, thunkAPI) => {
        const result = await axios.put<ITextContent>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);

export const partialUpdateEntity = createAsyncThunk(
    'textContent/partial_update_entity',
    async (entity: ITextContent, thunkAPI) => {
        const result = await axios.patch<ITextContent>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);

export const deleteEntity = createAsyncThunk(
    'textContent/delete_entity',
    async (id: string | number, thunkAPI) => {
        const requestUrl = `${apiUrl}/${id}`;
        const result = await axios.delete<ITextContent>(requestUrl);
        thunkAPI.dispatch(getEntities({}));
        return result;
    },
    {serializeError: serializeAxiosError}
);

// slice

export const TextContentSlice = createEntitySlice({
    name: 'textContent',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getEntity.fulfilled, (state, action) => {
                state.loading = false;
                state.entity = action.payload.data;
            })
            .addCase(deleteEntity.fulfilled, state => {
                state.updating = false;
                state.updateSuccess = true;
                state.entity = {};
            })
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const {data, headers} = action.payload;

                return {
                    ...state,
                    loading: false,
                    entities: data.data,
                    pageInfo: data.pageInfo,
                    totalItems: parseInt(headers['x-total-count'], 10),
                };
            })
            .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
                state.updating = false;
                state.loading = false;
                state.updateSuccess = true;
                state.entity = action.payload.data;
            })
            .addMatcher(isPending(getEntities, getEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.loading = true;
            })
            .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
                state.errorMessage = null;
                state.updateSuccess = false;
                state.updating = true;
            });
    },
});

export const {reset} = TextContentSlice.actions;

// Reducer
export default TextContentSlice.reducer;
