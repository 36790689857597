import React, {useEffect, useState} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import TextContentImport from "app/entities/text-content/text-content-import";
import {AUTHORITIES, IMPORT_MODE} from "app/config/constants";
import {useAppDispatch, useAppSelector} from "app/config/store";
import {getActiveEntities as lanContentGetEntities} from "app/entities/language/language.reducer";
import TextContentRevert from "app/entities/text-content/text-content-revert";
import {hasAnyAuthority} from "app/shared/auth/private-route";

const TabName = {
    TEXT_CODE: "TEXT_CODE",
    TRANSLATION: "TRANSLATION",
    REVERT: "REVERT",
}

export const ImportManage = () => {

    const dispatch = useAppDispatch();
    const langList = useAppSelector(state => state.language.entities);
    const [activeTab, setActiveTab] = useState(TabName.TEXT_CODE);
    const isDeveloper = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.DEVELOPER]));

    useEffect(() => {
        dispatch(lanContentGetEntities({}));
    }, []);

    return (
        <div className={"mt-3"}>
            <Nav tabs>
                <NavItem>
                    <NavLink className={activeTab == TabName.TEXT_CODE ? 'active' : ''}
                             onClick={() => setActiveTab(TabName.TEXT_CODE)}>
                        Insert Text Code
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={activeTab == TabName.TRANSLATION ? 'active' : ''}
                             onClick={() => setActiveTab(TabName.TRANSLATION)}>
                        Update Translation
                    </NavLink>
                </NavItem>
                {isDeveloper && (
                    <NavItem>
                        <NavLink className={activeTab == TabName.REVERT ? 'active' : ''}
                                 onClick={() => setActiveTab(TabName.REVERT)}>
                            Revert Previous Import
                        </NavLink>
                    </NavItem>
                )}
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={TabName.TEXT_CODE}>
                    <TextContentImport data={langList} mode={IMPORT_MODE.TEXT_CODE} onClose={undefined}/>
                </TabPane>
                <TabPane tabId={TabName.TRANSLATION}>
                    <TextContentImport data={langList} mode={IMPORT_MODE.TRANSLATION} onClose={undefined}/>
                </TabPane>
                {isDeveloper && (
                    <TabPane tabId={TabName.REVERT}>
                        <TextContentRevert onClose={undefined}/>
                    </TabPane>
                )}
            </TabContent>
        </div>
    );
};

export default ImportManage;
