export interface ITextContent {
    id?: number;
    code?: string;
    string?: string | null;
    rangeId?: number
}

export interface ITextContentSearch {
    code?: string;
    translation?: string;
    applicationId?: number;
    categoryId?: number;
    showUntranslated?: boolean
}

export interface ITextContentExportFilterCriteria {
    includeCodeID: boolean;
    applicationId?: number;
    categoryId?: number;
}


export const defaultValue: Readonly<ITextContent> = {};
