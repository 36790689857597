import axios from "axios";

interface ImportRes {
    message?: string;
    complete?: boolean;
    success?: boolean;
}

export const importCheck = async (file, language): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    const checkResult = await axios.post<ImportRes>(`/api/import/check?language=${language}`, formData, config);
    if (checkResult.data.success) {
        return Promise.resolve(checkResult.data.message);
    } else {
        return Promise.reject(checkResult.data.message);
    }
}

export const importUploadTranslation = async (file, language) => {

    const formData = new FormData();
    formData.append("file", file);

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    return await axios.post<ImportRes>(`/api/import/upload?target=trans&language=${language}`, formData, config);
}

export const importUploadTextCode = async (file) => {
    if (!file) throw Error("File must be uploaded")

    const formData = new FormData();
    formData.append("file", file);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    return await axios.post<ImportRes>(`/api/import/upload?target=code`, formData, config);
}
