export interface ICategory {
    id?: number,
    name?: string,
    categoryId?: number;
    categoryName?: string;
    description?: string;
    prefix?: string;
    minSubRange?: number;
    maxSubRange?: number;
    applicationId?: number;
}

export const defaultValue: Readonly<ICategory> = {};
