import {ExportToCsv} from 'export-to-csv';
import axios, {AxiosResponse} from "axios";
import {ITextContent, ITextContentExportFilterCriteria} from "app/shared/model/text-content.model";
import {ITranslation} from "app/shared/model/translation.model";

const baseOptions = {
    fieldSeparator: ',',
    quoteStrings: '',
    decimalSeparator: '.',
    showLabels: false,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true
};

export const exportTextCode = async (filter: ITextContentExportFilterCriteria) => {
    const isIncludeCodeID = filter.includeCodeID
    const params: any = {};
    if (!!filter.applicationId) params["applicationId.equals"] = filter.applicationId;
    if (!!filter.categoryId) params["categoryId.equals"] = filter.categoryId;

    const allTextCodes: AxiosResponse = await axios.get<ITextContent[]>('api/text-contents', {
        params: params,
    });

    const exportData = allTextCodes.data.map(data => {
        let item: any = {
            "code": data.code
        }
        if (isIncludeCodeID) item["code_id"] = data.rangeId
        return item;
    });
    exportDataToCSV(exportData, "text_code_" + new Date().getTime());
}
export const exportByLanguage = async (languageChoices, includeUntranslated, isMultiFile) => {

    const map = {};
    let allLanguageNames = ""
    languageChoices.forEach(lan => {
        map[lan.id] = lan.langCode;
        allLanguageNames = allLanguageNames.concat(lan.langCode).concat("_");
    })

    const promises = languageChoices.map(l => axios.get<ITranslation[]>(`api/translations?langId.equals=${l.id}`));

    /* Map of langId and
        {
            "language": ,
            "code": ,
            "string":
        }
    */
    let langIdTranslationMap = {};
    // Map of langId and array of text content ID
    let langIdTextContentIdListsMap = {};

    /*
      Start to aggregate data from all translation array.
     */
    await Promise.allSettled(promises)
        .then(results => results.forEach(async (result) => {
                    if (result.status !== 'fulfilled')
                        return;

                    const langId = extractLangIdFromRes(result);
                    langIdTranslationMap[langId] = result.value.data.map(data => (
                            {
                                "language": map[data.langId],
                                "code": data.textContent.code,
                                "codeID": data.textContent.rangeId,
                                "string": data.transString
                            }
                        )
                    );
                    langIdTextContentIdListsMap[langId] = result.value.data.map(data => data.textContent.id);
                }
            )
        );


    // Handle untranslated
    if (includeUntranslated) {
        for (const langId of Object.keys(langIdTranslationMap)) {
            const textContentListIds = langIdTextContentIdListsMap[langId].join(',');
            const {data: untranslatedTextContents} = await axios.get<ITextContent[]>(`/api/text-contents/untranslated/${langId}`);

            // Add translate code + untranslated code
            langIdTranslationMap[langId] = langIdTranslationMap[langId].concat(untranslatedTextContents.map(data => (
                        {
                            "language": map[langId],
                            "code": data.code,
                            "codeID": data.rangeId,
                            "string": "UNTRANSLATED"
                        }
                    )
                )
            );
        }
    }

    const finalResult = [].concat(...Object.values(langIdTranslationMap));

    if (isMultiFile) {
        for (const lang of languageChoices) {
            const exportData = finalResult
                .filter(s => s.language == lang.langCode)
                .map(s => {
                        return ({
                            "code": s["code"],
                            "string": s["string"],
                            "codeID": s["codeID"]
                        })
                    }
                );
            exportDataToCSV(exportData, `export_${lang.langCode}_trans_` + new Date().getTime());
        }

    } else {
        exportDataToCSV(finalResult, `export_${allLanguageNames}trans_` + new Date().getTime());
    }

}

const exportDataToCSV = (data, fileName) => {
    const displayOptions = {
        ...baseOptions,
        filename: fileName
    };
    const csvExporter = new ExportToCsv(displayOptions);
    if (data.length === 0) throw new Error("Can not export empty data")
    csvExporter.generateCsv(data);
}

const extractLangIdFromRes = (result: any): string => {
    const url = new URL(result.value.request.responseURL);
    const searchParams = new URLSearchParams(url.search);
    return searchParams.get('langId.equals');
}