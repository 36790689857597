import React from 'react';
import {PlusSquareFill} from 'react-bootstrap-icons';
import {createEntity as transCreateEntity, deleteEntity as transDeleteEntity} from '../translation/translation.reducer';
import {useAppDispatch} from "app/config/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {Tooltip as ReactTooltip} from "react-tooltip";


export const TextContentCell = ({transString, textContent, language, refresh, ...props}) => {
    const dispatch = useAppDispatch();
    const onCreateEmptyCode = async (e) => {
        e.preventDefault();
        const newTrans = {
            langId: (language.id),
            textContent: {
                id: textContent.id
            },
            transString: ""
        }
        await dispatch(transCreateEntity(newTrans));
        await refresh();
    }

    const onDelete = async (e) => {
        e.preventDefault();
        const {id} = textContent.allTranslations[language.langCode];
        await dispatch(transDeleteEntity(id));
        await refresh();
    }

    // ADD VIEW FOR CELL
    if (transString === undefined) {
        return (<div className="container w-100">
            <div className="row d-flex align-items-baseline clickable">
                <div className="col-md-11 text-left text-muted text-truncate h-100"
                     data-tooltip-id={"add_text_code"}
                     onDoubleClick={(e) => onCreateEmptyCode(e)}
                >
                    {"Empty"}
                </div>
                <ReactTooltip
                    id="add_text_code"
                    place="top-start"
                    content="Doubble click to add translation"
                />
                <div className="col-md-1 text-right text-success">
                    <PlusSquareFill
                        style={{cursor: 'pointer'}}
                        size={14}
                        onClick={(e) => onCreateEmptyCode(e)}
                    />
                </div>
            </div>
        </div>);
    } else {

        // UPDATE AND DELETE VIEW FOR CELL
        return (
            <div className="container w-100">
                <div className="row d-flex align-items-baseline">
                    <div className="col-md-11 text-left text-truncate">
                        {transString}
                    </div>
                    <div className="col-md-1 text-right">
                        <FontAwesomeIcon icon={faTrash}
                                         onClick={(e) => onDelete(e)}
                                         style={{cursor: 'pointer'}}
                                         className="text-danger"
                        />
                    </div>
                </div>
            </div>
        );
    }
};
export default TextContentCell;
