import React, {useEffect, useState} from 'react';
import GenericHeader from "app/shared/component/generic-manage-header";
import {faLaptop} from "@fortawesome/free-solid-svg-icons";
import {IApplication} from "app/shared/model/application.model";
import {useAppDispatch, useAppSelector} from "app/config/store";
import {createEntity, partialUpdateEntity} from "app/entities/application/application.reducer";

const ApplicationForm = (
    {application = {}, isEdit = false, reload, onClose}: {
        application: IApplication,
        isEdit: boolean,
        reload: any,
        onClose: any
    }
) => {

    const dispatch = useAppDispatch();
    const [formApplication, setFormApplication] = useState<IApplication>(application);
    const updateSuccess = useAppSelector(state => state.application.updateSuccess);

    const onSubmit = async (e) => {
        e.preventDefault()
        if (isEdit) {
            await dispatch(partialUpdateEntity(formApplication))
            reload()
            return
        }

        await dispatch(createEntity(formApplication))
        reload()
    }

    useEffect(() => {
        if (updateSuccess) onClose()
    }, [updateSuccess]);

    return (
        <>
            <GenericHeader
                icon={faLaptop}
                title={`${isEdit ? "Edit" : "Add"} Application`}
                size={0}
                meta={false}
            />

            <hr className="mt-3 mb-2"/>
            <form onSubmit={onSubmit}>
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className="col-6">
                            <label htmlFor="name"
                                   className="form-label">
                                Name <span className={"text-danger"}>*</span>
                            </label>
                            <input type="text"
                                   className={"form-control form-control-sm"}
                                   onChange={
                                       e => setFormApplication({
                                           ...formApplication,
                                           name: e.currentTarget.value
                                       })
                                   }
                                   value={!!formApplication.name ? formApplication.name : ""}
                                   required
                                   id="name"/>
                        </div>
                        <div className="col-6">
                            <label htmlFor="prefix"
                                   className="form-label">Prefix <span className={"text-danger"}>*</span>
                            </label>
                            <input type="text"
                                   className={"form-control form-control-sm"}
                                   id="prefix"
                                   onChange={
                                       e => setFormApplication({
                                           ...formApplication,
                                           prefix: e.currentTarget.value
                                       })
                                   }
                                   required
                                   value={!!formApplication.prefix ? formApplication.prefix : ""}
                            />
                        </div>
                        <div className="col-6 mt-1">
                            <label htmlFor="minRange"
                                   className="form-label">
                                Min Range <span className={"text-danger"}>*</span>
                            </label>
                            <input type="number"
                                   className={"form-control form-control-sm"}
                                   id="minRange"
                                   onChange={
                                       e => setFormApplication({
                                           ...formApplication,
                                           minRange: parseInt(e.currentTarget.value)
                                       })
                                   }
                                   value={(formApplication.minRange != null) ? formApplication.minRange : null}
                                   disabled={isEdit}
                            />
                        </div>
                        <div className="col-6 mt-1">
                            <label htmlFor="maxRange"
                                   className="form-label">
                                Max Range <span className={"text-danger"}>*</span>
                            </label>
                            <input type="number"
                                   className={"form-control form-control-sm"}
                                   id="maxRange"
                                   onChange={
                                       e => setFormApplication({
                                           ...formApplication,
                                           maxRange: parseInt(e.currentTarget.value)
                                       })
                                   }
                                   value={(formApplication.maxRange != null) ? formApplication.maxRange : null}
                                   disabled={isEdit}
                            />
                        </div>
                        <div className="col-12 mt-1">
                            <label htmlFor="description"
                                   className="form-label">
                                Description
                            </label>
                            <textarea
                                className="form-control"
                                id="description" rows={3}
                                onChange={
                                    e => setFormApplication({
                                        ...formApplication,
                                        description: e.currentTarget.value
                                    })
                                }
                                value={!!formApplication.description ? formApplication.description : ""}
                            >
                            </textarea>
                        </div>
                        <button type="submit" className="btn btn-primary mt-3 rounded-0">Submit</button>
                    </div>
                </div>
            </form>
        </>
    );
};
export default ApplicationForm;

