import React from 'react';
import {Translate} from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';
import {useAppSelector} from "app/config/store";
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {AUTHORITIES} from "app/config/constants";

const EntitiesMenu = () => {

    const isDeveloper = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.DEVELOPER]));
    const isAgent = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.AGENT]));

    if (isAgent) {
        return (<>
            <MenuItem icon="asterisk" to="/translation">
                <Translate contentKey="global.menu.entities.translation"/>
            </MenuItem>

            <MenuItem icon="asterisk" to="/change-log">
                <Translate contentKey="global.menu.entities.changeLog"/>
            </MenuItem>
            <MenuItem icon="asterisk" to="/import">
                <Translate contentKey="global.menu.entities.import"/>
            </MenuItem>
            <MenuItem icon="asterisk" to="/application">
                <Translate contentKey="global.menu.entities.application"/>
            </MenuItem>
        </>)
    }

    if (isDeveloper) {
        return (<>
            <MenuItem icon="asterisk" to="/translation">
                <Translate contentKey="global.menu.entities.translation"/>
            </MenuItem>
            <MenuItem icon="asterisk" to="/change-log">
                <Translate contentKey="global.menu.entities.changeLog"/>
            </MenuItem>
            <MenuItem icon="asterisk" to="/import">
                <Translate contentKey="global.menu.entities.import"/>
            </MenuItem>
            <MenuItem icon="asterisk" to="/language">
                <Translate contentKey="global.menu.entities.language"/>
            </MenuItem>
            <MenuItem icon="asterisk" to="/application">
                <Translate contentKey="global.menu.entities.application"/>
            </MenuItem>
        </>)
    }

    return (
        <>
            <MenuItem icon="asterisk" to={"/invitation-form"}>
                <span>Request form</span>
            </MenuItem>
        </>
    );
};

export default EntitiesMenu;
