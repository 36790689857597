import React, {useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useAppDispatch, useAppSelector} from 'app/config/store';

import {getEntity} from './translation.reducer';

export const TranslationDetail = () => {
    const dispatch = useAppDispatch();

    const {id} = useParams<'id'>();

    useEffect(() => {
        dispatch(getEntity(id));
    }, []);

    const translationEntity = useAppSelector(state => state.translation.entity);
    return (
        <Row>
            <Col md="8">
                <h2 data-cy="translationDetailsHeading">
                    <Translate contentKey="messageStringApp.translation.detail.title">Translation</Translate>
                </h2>
                <dl className="jh-entity-details">
                    <dt>
            <span id="id">
              <Translate contentKey="messageStringApp.translation.id">Id</Translate>
            </span>
                    </dt>
                    <dd>{translationEntity.id}</dd>
                    <dt>
            <span id="langId">
              <Translate contentKey="messageStringApp.translation.langId">Lang Id</Translate>
            </span>
                    </dt>
                    <dd>{translationEntity.langId}</dd>
                    <dt>
            <span id="textContentId">
              <Translate contentKey="messageStringApp.translation.textContentId">Text Content Id</Translate>
            </span>
                    </dt>
                    <dd>{translationEntity.textContentId}</dd>
                    <dt>
            <span id="transString">
              <Translate contentKey="messageStringApp.translation.transString">Trans String</Translate>
            </span>
                    </dt>
                    <dd>{translationEntity.transString}</dd>
                </dl>
                <Button tag={Link} to="/translation" replace color="info" data-cy="entityDetailsBackButton">
                    <FontAwesomeIcon icon="arrow-left"/>{' '}
                    <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
                </Button>
                &nbsp;
                <Button tag={Link} to={`/translation/${translationEntity.id}/edit`} replace color="primary">
                    <FontAwesomeIcon icon="pencil-alt"/>{' '}
                    <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
                </Button>
            </Col>
        </Row>
    );
};

export default TranslationDetail;
