import React, {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'app/config/store';
import {getEntities as appGetEntities} from "app/entities/application/application.reducer";
import {getEntities as categoryGetEntities} from "app/entities/category/category.reducer";
import {ITextContentSearch} from "app/shared/model/text-content.model";
import {Input, Label} from "reactstrap";

export const TextContentSearch = ({onSearch}) => {
    const dispatch = useAppDispatch();
    const applicationList = useAppSelector(state => state.application.entities);
    const categoryList = useAppSelector(state => state.category.entities);

    // CONST
    const TIME_DELAY_FOR_SEARCH = 300 // 300 ms

    // STATE
    const [searchParam, setSearchParam] = useState<ITextContentSearch>({});
    const [appCategory, setAppCategory] = useState({
        application: null,
        category: null,
    });

    // REF
    const categoryOptions = appCategory.application?.categories ?? [];

    useEffect(() => {
        dispatch(appGetEntities({}));
        dispatch(categoryGetEntities({}));
    }, []);

    useEffect(() => {
        const buildQuery = constructQuery(searchParam)
        const queryTimer = setTimeout(onSearch, TIME_DELAY_FOR_SEARCH, buildQuery)
        return () => {
            clearTimeout(queryTimer);
        };
    }, [searchParam]);

    const constructQuery = (searchParam) => {
        let query = ''
        if (!!searchParam.code) {
            query = query.length == 0 ? `code.contains=${searchParam.code}`
                : `${query}&code.contains=${searchParam.code}`;
        }
        if (!!searchParam.translation) {
            query = query.length == 0 ? `translation.contains=${searchParam.translation}`
                : `${query}&translation.contains=${searchParam.translation}`;
        }
        if (!!searchParam.applicationId) {
            query = query.length == 0 ? `applicationId.equals=${searchParam.applicationId}`
                : `${query}&applicationId.equals=${searchParam.applicationId}`;
        }
        if (!!searchParam.categoryId) {
            query = query.length == 0 ? `categoryId.equals=${searchParam.categoryId}`
                : `${query}&categoryId.equals=${searchParam.categoryId}`;
        }
        if (searchParam.showUntranslated) {
            query = query.length == 0 ? `translation.specified=${searchParam.showUntranslated}`
                : `${query}&translation.specified=${searchParam.showUntranslated}`;
        }
        if (query.length != 0) {
            query = query + '&distinct=true'
        }
        return query;
    }

    const onSelectApplication = (event) => {
        const selectAppID = event.target.value;
        const selectApplication = applicationList.find(app => app.id == selectAppID)
        const selectCategory = selectApplication.categories;

        setAppCategory({
            ...appCategory,
            application: selectApplication,
            category: selectCategory.length == 0 ? null : selectCategory
        })

        let newSearchParam = {
            ...searchParam,
            applicationId: selectApplication.id
        };

        // Default we choose the first category
        if (selectCategory.length > 0) {
            newSearchParam = {
                ...newSearchParam,
                categoryId: selectCategory[0].id
            }
        } else { // Or else set it to null
            newSearchParam = {
                ...newSearchParam,
                categoryId: null
            }
        }
        setSearchParam(newSearchParam)
    }

    const onSelectCategory = (event) => {
        const selectCategoryID = event.target.value;
        const selectCategory = categoryList.find(c => c.id == selectCategoryID)

        setAppCategory({
            ...appCategory,
            category: selectCategory
        })
        setSearchParam({
            ...searchParam,
            categoryId: selectCategory.id
        })
    }

    const onClear = (e) => {
        e.preventDefault()
        setSearchParam({})
        setAppCategory({
            application: null,
            category: null
        })
    };

    const onShowUntranslated = (e) => {
        setSearchParam({
            ...searchParam,
            translation: null,
            showUntranslated: e.target.checked
        })
    }

    return (
        <form className={"row"}>
            <div className="col-3">
                <div className="input-group">
                    <div className="input-group-text rounded-0">Code</div>
                    <input type="text"
                           className="form-control rounded-0"
                           onChange={
                               e => setSearchParam({
                                   ...searchParam,
                                   code: e.currentTarget.value
                               })
                           }
                           value={!!searchParam.code ? searchParam.code : ""}
                    />
                </div>
            </div>
            <div className="col-3">
                <div className="input-group">
                    <div className="input-group-text rounded-0">Translation</div>
                    <input type="text"
                           className="form-control rounded-0"
                           onChange={
                               e => setSearchParam({
                                   ...searchParam,
                                   translation: e.currentTarget.value,
                                   showUntranslated: false
                               })
                           }
                           value={!!searchParam.translation ? searchParam.translation : ""}
                    />
                </div>
            </div>
            <div className="col-3">
                <div className="input-group">
                    <div className="input-group-text rounded-0">Application</div>
                    <select className="form-select rounded-0"
                            aria-label="Select application"
                            onChange={onSelectApplication}
                            required
                    >
                        <option value="" selected={appCategory.application == null} disabled hidden>Select application
                        </option>
                        {
                            applicationList.map((app, index) => <option
                                value={app.id}
                                key={index}
                            >
                                {app.name}
                            </option>)
                        }
                    </select>
                </div>
            </div>

            <div className="col-2">
                <div className="input-group">
                    <div className="input-group-text rounded-0">Category</div>
                    <select className="form-select rounded-0"
                            aria-label="Select category"
                            disabled={appCategory.application == null || categoryOptions.length == 0}
                            onChange={onSelectCategory}
                            required>
                        <option value="" selected={appCategory.category == null} key={null} disabled hidden>Select
                            category
                        </option>
                        {
                            categoryOptions.map((category, index) => <option
                                value={category.id}
                                key={index}
                            >
                                {category.name}
                            </option>)
                        }
                    </select>
                </div>
            </div>
            <div className="col-1 d-flex align-items-end">
                <button className="w-100 btn btn-secondary align-bottom rounded-0"
                        onClick={(e) => onClear(e)}>
                    Clear
                </button>
            </div>
            <div>
                <div className={'col-12 mt-2 clickable'}>
                    <Label className={"align-baseline"}>
                        <Input type="checkbox"
                               className={"me-2 clickable"}
                               onClick={onShowUntranslated}
                               checked={searchParam.showUntranslated}
                        />Exclude untranslated in all languages
                    </Label>
                </div>
            </div>
        </form>
    );
};

export default TextContentSearch;
