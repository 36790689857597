import {ICategory} from "app/shared/model/category.model";

export interface IApplication {
    id?: number;
    name?: string | null;
    description?: string | null;
    minRange?: number;
    maxRange?: number;
    prefix?: string;
    categories?: ICategory[]
}

export interface IApplicationCell {
    id?: number;
    name?: string | null;
    description?: string | null;
    range?: string;
    prefix?: string;
}

export const defaultValue: Readonly<IApplication> = {};
