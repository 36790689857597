import axios from 'axios';

import {IChangeLog, IQueryChangeLogParams} from 'app/shared/model/change-log.model';
import PaginationResponse from "app/shared/model/rest.model";

const apiUrl = 'api/change_log';
export const getAllChangeLog = async (queryParam: IQueryChangeLogParams, pageNumber: number) => {

    const requestUrl = `${apiUrl}`;
    const params: any =
        {
            page: !!pageNumber ? pageNumber : 0,
            size: 10,
            sort: "id,desc"
        };

    if (!!queryParam.entityType) params["entityType.equals"] = queryParam.entityType;
    if (!!queryParam.entityId) params["entityId.equals"] = queryParam.entityId;
    if (!!queryParam.actor) params["actor.equals"] = queryParam.actor;
    if (!!queryParam.timeStamp) params["timeStamp.equals"] = queryParam.timeStamp;
    if (!!queryParam.actionType) params["actionType.equals"] = queryParam.actionType;
    if (!!queryParam.id) params["id.equals"] = queryParam.id;

    return axios.get<PaginationResponse<IChangeLog>>(requestUrl, {
        params: params,
    });
};

export const getChangeLogByTextContentId = async (id: number) => {
    const requestUrl = `${apiUrl}/text_content/${id}`;
    return axios.get<PaginationResponse<IChangeLog>>(requestUrl);
};
