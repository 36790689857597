import React from 'react';
import {Route} from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Translation from './translation';
import Language from './language';
import ChangeLog from './change_log';
import Application from './application';
import Import from './import';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
    return (
        <div>
            <ErrorBoundaryRoutes>
                {/* prettier-ignore */}
                <Route path="translation/*" element={<Translation/>}/>
                <Route path="language/*" element={<Language/>}/>
                {/*<Route path="text-content/*" element={<TextContent/>}/>*/}
                <Route path="change-log/*" element={<ChangeLog/>}/>
                <Route path="application/*" element={<Application/>}/>
                <Route path="import/*" element={<Import/>}/>
                {/* jhipster-needle-add-route-path - JHipster will add routes here */}
            </ErrorBoundaryRoutes>
        </div>
    );
};
