import './home.scss';

import React from 'react';
import {Link} from 'react-router-dom';
import {Translate} from 'react-jhipster';
import {Row, Col, Alert} from 'reactstrap';

import {useAppSelector} from 'app/config/store';

export const Home = () => {
    const account = useAppSelector(state => state.authentication.account);

    return (
        <Row>
            <Col md="3" className="pad">
                <span className="hipster rounded"/>
            </Col>
            <Col md="9">
                <h4 className={"display-3"}>
                    <p>Welcome !</p>
                </h4>
                <p className="lead">
                    Message table service is a centralized place for multi-language management
                </p>
                {account?.login ? (
                    <div>
                        <Alert color="success">
                            <Translate contentKey="home.logged.message" interpolate={{username: account.login}}>
                                You are logged in as user {account.login}.
                            </Translate>
                        </Alert>
                    </div>
                ) : (
                    <div>
                        <Alert color="warning">
                            <span>Please </span>
                            <Link to="/login" className="alert-link">
                                <Translate contentKey="global.messages.info.authenticated.link"> sign in</Translate>
                            </Link>, to sign in with SSO server
                            <span> or </span>
                            <Link to="/login" className="alert-link">
                                <span> create new user</span>
                            </Link>
                            <br/>
                            <span className={"mt-2"}> Or read </span>
                            <Link target='_blank' to="/swagger-ui/index.html" className="alert-link">
                                <span> Swagger</span>
                            </Link>
                            , for document reference.
                        </Alert>
                    </div>
                )}

            </Col>
        </Row>
    );
};

export default Home;
