import React from 'react';
import {IRangeProgressData} from "app/shared/model/shared.model";


interface RangeProgressBarProps {
    data?: IRangeProgressData;
}

const retrievePercentage = (maxSubRange: number, minSubRange: number, minRange: number, maxRange: number) => {
    return (maxSubRange - minSubRange) / (maxRange - minRange);
}

const colorArray = [
    "primary",
    "success",
];
export const RangeProgressBar: React.FC<RangeProgressBarProps> = ({data}) => {

    return (<>
        {
            <div className="mt-3 progress rounded-0" style={{"height": "0.5em"}}>
                {
                    data.subRanges.map((subRange, idx) =>
                        <div
                            className={`progress-bar bg-${colorArray[idx % colorArray.length]} progress-bar-animated progress-bar-striped`}
                            role="progressbar"
                            style={{"width": `${retrievePercentage(subRange.maxSubRange, subRange.minSubRange, data.minRange, data.maxRange) * 100}%`}}>
                        </div>)
                }
            </div>
        }
    </>);
};

export default RangeProgressBar;
