import React from 'react';
import * as Flags from 'country-flag-icons/react/3x2'
import {getCountryCode} from "app/shared/util/string-utils";

export const LangWithFlag = ({code}) => {

  const Flag = Flags[(getCountryCode(code))];
  // @ts-nocheck
  return (<>
      {Flag !== undefined && <Flag style={{"height": "15px"}}/>} {code}</>
  )
};

export default LangWithFlag;
