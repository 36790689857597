import React, {useCallback, useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import {useAppDispatch, useAppSelector} from 'app/config/store';
import {deleteEntity, getEntities} from "app/entities/application/application.reducer";
import {deleteEntity as deleteCategoryEntity} from "app/entities/category/category.reducer";
import GenericHeader from "app/shared/component/generic-manage-header";
import {faAdd, faLaptopCode, faPlusSquare} from "@fortawesome/free-solid-svg-icons";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IApplication, IApplicationCell} from "app/shared/model/application.model";
import {baseModalStyles} from "app/shared/styles/modal-styles";
import useModals from "app/hooks/useModals";
import {Button} from "reactstrap";
import CloseButton from "react-bootstrap/CloseButton";
import ApplicationForm from "app/entities/application/application-form";
import {AgGridReact} from "ag-grid-react";
import CategoryForm from "app/entities/category/category-form";
import useEscKeyDown from "app/hooks/useEscKeyDown";
import CopyableComponent from "app/shared/component/copy-to-clipboard";
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {AUTHORITIES} from "app/config/constants";
import RangeProgressBar from "app/shared/component/range-progress-bar";
import {IRangeProgressData} from "app/shared/model/shared.model";

const modalStyles = {
    FORM_CATEGORY: {
        content: {
            ...baseModalStyles,
            width: '35%',
        },
    },
    FORM_APP: {
        content: {
            ...baseModalStyles,
            width: '35%',
        },
    }
};

const buttonAction = {
    FORM_CATEGORY: 'FORM_CATEGORY',
    FORM_APP: 'FORM_APP'
};

const entityType = {
    CATEGORY: "CATEGORY",
    APPLICATION: "APPLICATION"
}

const buildProgressData = (data: IApplication) => {
    if (!data) return null
    const result: IRangeProgressData = {
        maxRange: data.maxRange,
        minRange: data.minRange,
        subRanges: data.categories.map(c => {
            return (
                {
                    name: c.name,
                    minSubRange: c.minSubRange,
                    maxSubRange: c.maxSubRange
                }
            )
        })
    }
    return result;
}

export const ApplicationManage = () => {

    const isDeveloper = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.DEVELOPER]));

    /* DATA DEF */
    const containerStyle = {
        width: '100%'
    };

    const defaultColDef = {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        wrapText: true,
        autoHeight: true,
        autoHeaderHeight: true,
    };

    const colDef: any = [
        {
            field: 'name',
            headerName: 'Name',
            cellStyle: {textAlign: 'left'},
            cellRenderer: (params: any) => {
                return (
                    <>
                        {!!params.value
                            && <CopyableComponent
								children={<span
                                    className={'text-truncate text-left w-100 text-primary clickable'}
                                    onClick={() => {
                                        if (!isDeveloper) return;
                                        handleModal(true, buttonAction.FORM_APP, {
                                            applicationData: params.data,
                                            isEdit: true,
                                        })
                                    }
                                    }>
                                    {params.value}
                            </span>} text={params.value}
							/>
                        }
                    </>
                );
            },
        },
        {
            field: 'categoryName',
            headerName: 'Category',
            cellStyle: {textAlign: 'left'},
            cellRenderer: (params: any) => {
                return (
                    <>
                        {!!params.value
                            && <CopyableComponent
								children={<span
                                    className={'text-truncate text-left w-100 text-primary clickable'}
                                    onClick={() => {
                                        if (!isDeveloper) return;
                                        handleModal(true, buttonAction.FORM_CATEGORY, {
                                            categoryData: params.data,
                                            isEdit: true,
                                        })
                                    }
                                    }>
                                    {params.value}
                            </span>} text={params.value}
							/>
                        }
                    </>
                );
            },
        },
        {
            field: 'range',
            headerName: 'Range',
            cellStyle: {textAlign: 'left'}
        },
        {
            field: 'subRange',
            headerName: 'Sub-Range',
            cellStyle: {textAlign: 'left'}
        },
        {
            field: 'prefix',
            headerName: 'Prefix',
            cellStyle: {textAlign: 'left'}
        },
        {
            field: 'description',
            headerName: 'Description',
            cellStyle: {textAlign: 'left'}
        },
        {
            field: 'id',
            headerName: '',
            cellStyle: {textAlign: 'left'},
            cellRenderer: (params: any) => {
                const application = applicationList.find(app => params.value == app.id);
                const progressData = buildProgressData(application);
                return (
                    <>
                        {!!params.value
                            && <RangeProgressBar data={progressData}/>
                        }
                    </>
                );
            },
        },
    ];

    if (isDeveloper) {
        colDef.push({
            field: 'id',
            headerName: 'Action',
            maxWidth: 100,
            cellRenderer: (params: any) => {
                return <FontAwesomeIcon icon={faTrash}
                                        onClick={(e) => onDelete(e, params.data)}
                                        style={{cursor: 'pointer'}}
                                        className="text-danger"
                />
            }
        })
    }

    const dispatch = useAppDispatch();
    const applicationList: IApplication[] = useAppSelector(state => state.application.entities);
    const applicationLoading = useAppSelector(state => state.application.loading);
    const applicationUpdateSuccess = useAppSelector(state => state.application.updateSuccess);

    const categoryUpdateSuccess = useAppSelector(state => state.category.updateSuccess);
    const categoryLoading = useAppSelector(state => state.category.loading);

    const {open: openModal, data: dataModal, context: contextModal, handleModal} = useModals();
    useEscKeyDown(openModal, () => handleModal(false, 'Closing modal ...', {}))

    const calculateApplicationData = useCallback(() => {
        const apps = applicationList;
        let data: IApplicationCell[] = [];
        for (let i = 0; i < apps.length; i++) {
            let appRow: any = {};
            appRow.id = apps[i].id;
            appRow.name = apps[i].name;
            appRow.range = apps[i].minRange + '-' + apps[i].maxRange;
            appRow.prefix = apps[i].prefix;
            appRow.description = apps[i].description;
            appRow.minRange = apps[i].minRange;
            appRow.maxRange = apps[i].maxRange;
            appRow.type = entityType.APPLICATION;

            data.push(appRow)
            for (let j = 0; j < apps[i].categories.length; j++) {
                let categoryRow: any = {};
                categoryRow.categoryId = apps[i].categories[j].id;
                categoryRow.categoryName = apps[i].categories[j].name;
                categoryRow.subRange = apps[i].categories[j].minSubRange + '-' + apps[i].categories[j].maxSubRange;
                categoryRow.prefix = apps[i].categories[j].prefix;
                categoryRow.description = apps[i].categories[j].description;
                categoryRow.minSubRange = apps[i].categories[j].minSubRange;
                categoryRow.maxSubRange = apps[i].categories[j].maxSubRange;
                categoryRow.applicationId = apps[i].id;
                categoryRow.type = entityType.CATEGORY;
                data.push(categoryRow)
            }
        }
        return data;
    }, [applicationList]);

    const applicationData = calculateApplicationData()

    /* REF */
    const gridRef = useRef(null);

    /* HOOKS */
    useEffect(() => {
        reload()
    }, [])

    useEffect(() => {
        reload()
    }, [applicationUpdateSuccess]);

    useEffect(() => {
        reload()
    }, [categoryUpdateSuccess]);

    const reload = () => {
        dispatch(getEntities({}));
    }

    /* HANDLER */
    const onDelete = async (e, params) => {
        e.preventDefault()
        if (params.type === entityType.APPLICATION) {
            const applicationId = params.id;
            dispatch(deleteEntity(applicationId))
            reload()
            return
        }

        if (params.type === entityType.CATEGORY) {
            const categoryId = params.categoryId;
            dispatch(deleteCategoryEntity(categoryId))
            reload()
            return
        }
    }

    const onGridReady = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
        gridRef.current.api.setDomLayout('autoHeight');
    }, []);

    return (
        <>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col-6"}>
                        <GenericHeader
                            icon={faLaptopCode}
                            title={"Manage Applications"}
                            size={applicationList.length}
                            meta={false}
                        />
                    </div>
                    {isDeveloper && (
                        <div className={"col-6 text-end"}>
                            <Button onClick={() => handleModal(true, buttonAction.FORM_APP, {})}
                                    color={"success"}
                                    className={"rounded-0 me-2"}
                                    disabled={applicationLoading || categoryLoading}>
                                <FontAwesomeIcon icon={faPlusSquare} spin={applicationLoading}/>
                                {"  "} Add Application
                            </Button>
                            <Button onClick={() => handleModal(true, buttonAction.FORM_CATEGORY, {})}
                                    color={"success"}
                                    className={"rounded-0"}
                                    disabled={applicationLoading || categoryLoading}>
                                <FontAwesomeIcon icon={faAdd} spin={applicationLoading}/>
                                {"  "} Add Category
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <hr className="mt-3 mb-2"/>

            <div className={"col-12 mt-3 ag-theme-alpine"} style={containerStyle}>
                <AgGridReact
                    overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                    overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No data founds</span>'}
                    ref={gridRef as any}
                    rowData={applicationData}
                    columnDefs={colDef}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    enableCellTextSelection={true}
                />
            </div>

            {/* MODAL */}
            <Modal isOpen={openModal}
                   contentLabel="Application modal"
                   style={modalStyles[dataModal]}
                   closeTimeoutMS={100}
                   ariaHideApp={false}>
                <div className="text-end text-end float-right d-flex p-1" style={{float: 'right'}}>
                    <CloseButton
                        aria-label="Close"
                        onClick={() => {
                            handleModal(false, 'Closing modal ...', {});
                            reload();
                        }}
                    />
                </div>

                {dataModal === buttonAction.FORM_APP && (
                    <ApplicationForm
                        onClose={() => {
                            handleModal(false, 'Closing modal ...');
                        }}
                        reload={reload}
                        application={contextModal.applicationData}
                        isEdit={contextModal.isEdit}
                    />
                )}

                {dataModal === buttonAction.FORM_CATEGORY && (
                    <CategoryForm
                        onClose={() => {
                            handleModal(false, 'Closing modal ...');
                        }}
                        reload={reload}
                        category={contextModal.categoryData}
                        isEdit={contextModal.isEdit}
                        applicationList={applicationList}
                    />
                )}

            </Modal>
        </>
    );
};


export default ApplicationManage;
