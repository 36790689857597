import React, {useRef, useState} from 'react'
import Select from "react-select";
import {toast} from "react-toastify";
import {importUploadTextCode, importUploadTranslation} from "app/shared/util/import-utils";
import LangWithFlag from "app/shared/component/lang-with-flag";
import {Alert, Button} from "reactstrap";
import GenericHeader from "app/shared/component/generic-manage-header";
import {faFileImport} from "@fortawesome/free-solid-svg-icons";
import {IMPORT_MODE} from "app/config/constants";

const ImportTranslationForm = ({inputFileRef, loading, options, onChange, handleFile}) => {

    return (
        <>
            <div className="alert alert-primary" role="alert">
                Translation CSV format:
                <table className="table table-striped table-bordered table-sm w-100">
                    <thead>
                    <tr>
                        <th scope="col">code</th>
                        <th scope="col">string</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>CM_ERR_Unknown</td>
                        <td>Unknown error</td>
                    </tr>
                    <tr>
                        <td>CM_ERR_Server_down</td>
                        <td>Service temporarily unavailable</td>
                    </tr>
                    <tr>
                        <td>...</td>
                        <td>...</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <label htmlFor="id" className="col-form-label">Select Language:</label>
            <Select
                isLoading={loading}
                isSearchable={true}
                options={options}
                className={"basic-multi-select w-100"}
                classNamePrefix={"select"}
                maxMenuHeight={150}
                onChange={onChange}
                isClearable={true}
            />
            <label htmlFor="formFile" className={"mt-3 form-label"}>CSV file</label>
            <input className="form-control" type="file" id="formFile" onChange={handleFile} ref={inputFileRef}/>
        </>
    );
}

const ImportCodeForm = ({inputFileRef, handleFile}) => {
    return (
        <>
            <div className="alert alert-primary" role="alert">
                Text code CSV format:
                <table className="table table-striped table-bordered table-sm w-100">
                    <thead>
                    <tr>
                        <th scope="col">code</th>
                        <th scope="col">category_prefix</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>CM_ERR_Unknown</td>
                        <td>CM_ERR</td>
                    </tr>
                    <tr>
                        <td>CM_ERR_Server_down</td>
                        <td>CM_ERR</td>
                    </tr>
                    <tr>
                        <td>...</td>
                        <td>...</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <label htmlFor="formFile" className={"mt-3 form-label"}>CSV file</label>
            <input ref={inputFileRef} className="form-control" type="file" id="formFile" onChange={handleFile}/>
        </>
    );
}

export const TextContentImport = ({data, onClose, mode = IMPORT_MODE.TRANSLATION, ...props}) => {

    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [language, setLanguage] = useState(null);
    const inputRef = {
        textCode: useRef(null),
        trans: useRef(null)
    }
    const [alertContext, setAlertContext] = useState({
        visible: false,
        message: "",
        isError: false
    });

    const options = data.map((eachOption: { langCode: any; }) => {
            return {
                ...eachOption,
                value: eachOption.langCode,
                label: <LangWithFlag code={eachOption.langCode}/>,
            };
        }
    );

    const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const files = e.target.files[0]
        setFile(files);
    }

    const importTextCodeFile = (): void => {
        setLoading(true)
        importUploadTextCode(file)
            .then(res => {
                    if (res.data.success) toast.success("Import completed")
                    else toast.error(res.data.message)
                }
            ).catch(err => toast.error(err.message)
        )
        setLoading(false)
        setFile(null)
        inputRef.textCode.current.value = ""
    }

    const importTranslationFile = (): void => {
        if (!file) {
            toast.error("Empty file csv")
            return
        }
        setLoading(true)
        importUploadTranslation(file, language)
            .then(res => {
                    let alertInfo = {
                        visible: true,
                        message: "",
                        isError: false
                    }
                    if (res.data.success) {
                        toast.success("Import completed")
                        alertInfo = {
                            ...alertInfo,
                            message: res.data.message,
                            isError: false
                        }
                    } else {
                        toast.error(res.data.message)
                        alertInfo = {
                            ...alertInfo,
                            message: res.data.message,
                            isError: true
                        }
                    }
                    setAlertContext(alertInfo)
                    setTimeout(() => {
                        setAlertContext({
                            visible: false,
                            message: "",
                            isError: false
                        })
                    }, 5000)

                }
            ).catch(err => toast.error(err.message)
        )
        setLoading(false)
        setFile(null)
        inputRef.trans.current.value = ""
    }

    // @ts-nocheck
    return (
        <>
            <GenericHeader icon={faFileImport}
                           title={"Import files"}
                           size={0}
                           meta={false}
            />
            <hr className="mt-3 mb-3"/>
            <form className="row">
                <div className="col-md-6 m-auto">

                    {
                        mode === IMPORT_MODE.TRANSLATION && <ImportTranslationForm
							inputFileRef={inputRef.trans}
							loading={loading}
							options={options}
							onChange={(e) => setLanguage(e.value)}
							handleFile={handleFileSelected}
						/>
                    }

                    {
                        mode === IMPORT_MODE.TEXT_CODE && <ImportCodeForm
							inputFileRef={inputRef.textCode}
							handleFile={handleFileSelected}
						/>
                    }

                    {mode === IMPORT_MODE.TRANSLATION && <div className={'mt-3'}>
						<Alert color={`${alertContext.isError ? 'danger' : 'primary'}`} isOpen={alertContext.visible}>
                            {alertContext.message}
						</Alert>
						<Button className={"mt-1 rounded-0"} color="primary" onClick={() => importTranslationFile()}>
							Import
						</Button>
					</div>
                    }

                    {mode === IMPORT_MODE.TEXT_CODE && <div className={'mt-3'}>
						<Button className={"mt-1 rounded-0"} color="primary" onClick={() => importTextCodeFile()}>
							Import
						</Button>
					</div>
                    }
                </div>
            </form>
        </>
    );
};

export default TextContentImport;
