import React, {useState} from 'react';
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSortAlphaDown, faSortAlphaUp, faTableList} from "@fortawesome/free-solid-svg-icons";
import {Button} from "reactstrap";

interface pairAttribute {
    key: string,
    label: string
}

interface CustomSortOrderSelectionProps {
    selectableAttributes: pairAttribute[],
    onSortOrderChanged: any
}

const CustomSortOrderSelection = ({selectableAttributes, onSortOrderChanged}: CustomSortOrderSelectionProps) => {

    const [order, setOrder] = useState("DESC")
    const [selectAttribute, setSelectAttribute] = useState(selectableAttributes[0])

    return (
        <div className={"d-inline"}>
            <Dropdown className={"d-inline"}>
                <Dropdown.Toggle className={"rounded-0 me-2 d-inline"}>
                    <FontAwesomeIcon icon={faTableList}/> Order By: {selectAttribute.label}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        selectableAttributes.map(attribute => <Dropdown.Item
                            key={attribute.key}
                            onClick={() => {
                                onSortOrderChanged(null, attribute.key, order)
                                setSelectAttribute(attribute)
                            }}
                        >
                            {attribute.label}
                        </Dropdown.Item>)
                    }
                </Dropdown.Menu>
            </Dropdown>

            {
                order === "DESC" && <Button
                    outline
                    color={"primary"}
                    className={"rounded-0"}
                    onClick={() => {
                        setOrder("ASC")
                        onSortOrderChanged(null, null, "ASC")
                    }}
                >
					<FontAwesomeIcon icon={faSortAlphaDown}/>
                    {"  "} Desc
				</Button>
            }

            {
                order === "ASC" && <Button
					outline
					color={"primary"}
					className={"rounded-0"}
					onClick={() => {
                        setOrder("DESC")
                        onSortOrderChanged(null, null, "DESC")
                    }}
                >
					<FontAwesomeIcon icon={faSortAlphaUp}/>
                    {"  "} Asc
				</Button>
            }
        </div>
    );
};

export default CustomSortOrderSelection;
