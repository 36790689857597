import axios from 'axios';
import React, { useEffect } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setAuthToken } from 'app/shared/reducers/authentication';

export const Callback = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const location = useLocation();
  const tokenExchangeUrl = 'api/tokenexchange';
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('token');

  useEffect(() => {
    getToken();
  }, [code]);

  const getToken = (): void => {
    // make request to BE to bypass the CORS issue from FE
    dispatch(setAuthToken(code));
  };

  const { from } = (location.state as any) || { from: { pathname: '/translation', search: location.search } };
  if (isAuthenticated) {
    return <Navigate to={from} replace />;
  }
};

export default Callback;
