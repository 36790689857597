import React from 'react';

const codeStyle = {fontFamily: '"Courier New", monospace'}

const ValueDisplay = ({value}) => {

    if (value === undefined || value === null) {
        return <></>
    }

    let jsxToRender: any;
    switch (typeof value) {
        case "number":
            jsxToRender = <span className={"m-0 p-0 text-success ml-1"} style={codeStyle}>{value}</span>
            break
        case "string":
            jsxToRender = <span className={"m-0 p-0 text-primary ml-1"} style={codeStyle}>"{value}"</span>
            break
        case "boolean":
            jsxToRender =
                <span className={"m-0 p-0 text-danger ml-1"} style={codeStyle}> {value ? "true" : "false"} </span>
            break
        default:
            if (Array.isArray(value)) {
                jsxToRender =
                    <span className={"m-0 p-0 text-success ml-1"} style={codeStyle}>{JSON.stringify(value)}</span>
            } else if (!!value["id"]) {
                jsxToRender = <span className={"m-0 p-0 text-success ml-1"} style={codeStyle}>{value["id"]}</span>
            } else {
                jsxToRender =
                    <span className={"m-0 p-0 text-success ml-1"} style={codeStyle}>{JSON.stringify(value)}</span>
            }
            break
    }

    return (
        <>
            {jsxToRender}
        </>
    );
}

const DataChangeViewer = ({data}) => {

    if (data === null || data == undefined || Object.keys(data).length == 0) {
        return (<></>)
    }

    return (
        <>
            <p className={"m-0 p-0 mt-1"} style={codeStyle}>{""}</p>
            {
                Object.keys(data).filter(key => key != null).map(
                    (key) => <span key={key}>
                        <span className={"mt-0 mb-0 me-0 p-0 ms-1"} style={codeStyle}>"{key}":{" "}</span>
                        <ValueDisplay value={data[key]} key={key}/>
                        <br/>
                    </span>
                )
            }
            <p className={"m-0 p-0 mb-1"} style={codeStyle}>{""}</p>
        </>

    );
};

export default DataChangeViewer;
