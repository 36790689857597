import React from 'react';
import {Route} from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import TranslationDetail from './translation-detail';
import TranslationUpdate from './translation-update';
import TranslationDeleteDialog from './translation-delete-dialog';
import TranslationManage from "app/entities/translation/translation-manage";

const TranslationRoutes = () => (
    <ErrorBoundaryRoutes>
        <Route index element={<TranslationManage/>}/>
        <Route path="new" element={<TranslationUpdate/>}/>
        <Route path=":id">
            <Route index element={<TranslationDetail/>}/>
            <Route path="edit" element={<TranslationUpdate/>}/>
            <Route path="delete" element={<TranslationDeleteDialog/>}/>
        </Route>
    </ErrorBoundaryRoutes>
);

export default TranslationRoutes;
