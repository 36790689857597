import React, {useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'app/config/store';

import {createEntity as textContentCreateEntity} from './text-content.reducer';
import {getEntities as appGetEntities} from "app/entities/application/application.reducer";
import {faTable} from "@fortawesome/free-solid-svg-icons";
import GenericHeader from "app/shared/component/generic-manage-header";
import Select from "react-select";
import {toast} from "react-toastify";

export const TextContentAdd = ({onClose}) => {
    const dispatch = useAppDispatch();
    const applicationList = useAppSelector(state => state.application.entities);
    const applicationLoading = useAppSelector(state => state.application.loading);
    const textContentUpdateSuccess = useAppSelector(state => state.textContent.updateSuccess);
    const [code, setCode] = useState(null);
    const [prefix, setPrefix] = useState("");
    const [category, setCategory] = React.useState(null);
    const [app, setApp] = useState({
        categories: []
    });

    let categoryOptions: any[] = useMemo(() => {
        return app?.categories.map(c => {
            return {
                value: c,
                label: c.name
            }
        }, []);
    }, [app]);

    const appOptions: any[] = useMemo(() => {
        return applicationList.map(app => {
            return {
                value: app,
                label: app.name
            }
        }, []);
    }, [applicationList]);


    useEffect(() => {
        dispatch(appGetEntities({}));
    }, []);

    useEffect(() => {
        if (textContentUpdateSuccess) onClose()
    }, [textContentUpdateSuccess]);

    const onAdd = async (e) => {
        e.preventDefault();
        if (code.length == 0) {
            toast.error("Empty code can not be added")
            return
        }
        const newTextCode = {code: prefix + '_' + code, categoryId: category.value.id};
        await dispatch(textContentCreateEntity(newTextCode));
        setCode("");
    }

    // @ts-ignore
    return (
        <>
            <GenericHeader
                icon={faTable}
                title={"Add Text Content"}
                size={0}
                meta={false}
            />

            <hr className="mt-3 mb-2"/>

            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-12 m-auto"}>
                        <form>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <label htmlFor="application" className="form-label">Application</label>
                                    <Select
                                        isSearchable={true}
                                        options={appOptions}
                                        className={'basic-multi-select w-100'}
                                        classNamePrefix="select"
                                        onChange={value => {
                                            setCategory(null);
                                            setApp(value.value);
                                            setPrefix(value.value.prefix);
                                        }}
                                        isLoading={applicationLoading}
                                        isDisabled={applicationLoading}
                                        placeholder={"Select application"}
                                        required
                                    />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="application" className="form-label">Category</label>
                                    <Select
                                        isSearchable={true}
                                        options={categoryOptions}
                                        className={'basic-multi-select w-100'}
                                        classNamePrefix="select"
                                        onChange={value => {
                                            setCategory(value);
                                            setPrefix(value.value.prefix);
                                        }}
                                        value={category || ''}
                                        isLoading={applicationLoading}
                                        isDisabled={applicationLoading}
                                        placeholder={"Select category"}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="code" className="form-label">Code</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">{prefix}_</div>
                                    </div>
                                    <input type="text"
                                           className={"form-control form-control-sm"}
                                           id="code"
                                           value={code || ''}
                                           onChange={e => setCode(e.target.value)}
                                           required
                                    />
                                </div>

                            </div>


                            <div className="col-12 mt-4">
                                <button type="submit"
                                        className="btn btn-primary"
                                        onClick={onAdd}
                                        disabled={!category}
                                > Add
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TextContentAdd;
