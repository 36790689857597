import axios from 'axios';

import {IStatsActivity, IStatsTranslation} from "app/shared/model/stats.model";

const apiUrl = 'api/stats';

export const findAllActivitiesStats = async () => {
    const requestUrl = `${apiUrl}/activity_last_month`;
    return axios.get<IStatsActivity[]>(requestUrl);
};

export const findAllTranslationStats = async () => {
    const requestUrl = `${apiUrl}/translation`;
    return axios.get<IStatsTranslation[]>(requestUrl);
};