import React, {useCallback, useEffect, useRef, useState} from 'react';
import {PageInfo} from "app/shared/model/rest.model";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHistory} from "@fortawesome/free-solid-svg-icons";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {getAllChangeLog} from "app/entities/change_log/change-log.utils";
import {convertDateFromServer, convertDateTimeStampFromServer} from "app/shared/util/date-utils";
import {AgGridReact} from "ag-grid-react";
import DataChangeViewer from "app/shared/component/custom-json-viewer";
import {Badge} from "reactstrap";
import CustomPagination from "app/shared/component/custom-pagination";
import {IQueryChangeLogParams} from "app/shared/model/change-log.model";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const containerStyle = {
    width: '100%'
};

const entityTypeSelect = [
    "TEXTCONTENT", "TRANSLATION", "LANGUAGE", "CATEGORY"
]

const actionTypeSelect = [
    "CREATE", "DELETE", "UPDATE"
]


const defaultColumnDef = {
    resizable: true,
    initialWidth: 200,
    wrapHeaderText: true,
    wrapText: true,
    autoHeight: true,
    autoHeaderHeight: true,
};

const columnDef: any = [
    {
        field: 'id',
        maxWidth: 75
    },
    {
        field: 'entityId',
        maxWidth: 90
    },
    {
        field: 'entityType',
        maxWidth: 135
    },
    {
        field: 'timeStamp',
        headerName: 'Timestamp',
        cellRenderer: (params: any) => {
            return <b> {convertDateTimeStampFromServer(params.value)} </b>
        },
        maxWidth: 160
    },
    {
        field: 'actor',
        cellRenderer: (params: any) => {
            return <b className={"text-primary text-truncate"}> {params.value} </b>
        },
        maxWidth: 160
    },
    {
        field: 'before',
        cellRenderer: (params: any) => {
            return <DataChangeViewer data={params.value}/>
        }
    },
    {
        field: 'after',
        cellRenderer: (params: any) => {
            return <DataChangeViewer data={params.value}/>
        }
    },
    {
        field: 'actionType',
        maxWidth: 110,
        cellRenderer: (params: any) => {
            return <Badge
                color={params.value === "DELETE" ? "danger" : params.value === "CREATE" ? "success" : "primary"}
                pill={false}
                className={"me-1"}>{params.value}
            </Badge>
        }
    }
];

export const ChangeLogManage = () => {
    // CONST
    const TIME_DELAY_FOR_SEARCH = 300 // 300 ms

    // STATE
    const [searchParam, setSearchParam] = useState<IQueryChangeLogParams>({
        entityId: null,
        entityType: null,
        actor: null,
        timeStamp: null,
        actionType: null,
        id: null
    })
    const [data, setData] = useState<[]>([])
    const [loading, setLoading] = useState(true)
    const [pageNumber, setPageNumber] = useState(0)
    const [pageInfo, setPageInfo] = useState<PageInfo>({
            pageSize: 10,
            pageNumber: 0
        }
    )

    // REF
    const gridRef = useRef(null);

    // HOOKS
    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        const queryTimer = setTimeout(() => fetchData(), TIME_DELAY_FOR_SEARCH)
        return () => {
            clearTimeout(queryTimer);
        };
    }, [pageNumber])

    useEffect(() => {
        setPageNumber(0);
        const queryTimer = setTimeout(() => fetchData(), TIME_DELAY_FOR_SEARCH)
        return () => {
            clearTimeout(queryTimer);
        };
    }, [searchParam])

    const fetchData = async () => {
        setLoading(true);
        getAllChangeLog(searchParam, pageNumber)
            .then((res: any) => {
                    setData(res.data.data);
                    setPageInfo(res.data.pageInfo);
                }
            ).finally(() => {
                setLoading(false);
            }
        )
    }

    const onPageChange = async (pageNumber) => {
        setPageNumber(pageNumber);
    }

    const onGridReady = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
        gridRef.current.api.setDomLayout('autoHeight');
    }, []);

    const onClear = (e) => {
        e.preventDefault()
        setSearchParam({
            entityId: null,
            entityType: null,
            actor: null,
            timeStamp: null,
            actionType: null,
            id: null
        })
        setPageNumber(0)
        fetchData()
    };

    return (
        <div className={"fluid-container"}>
            <div>
                <span className={'fw-bold fs-4'}>
                    <FontAwesomeIcon icon={faHistory}/>{" "} Change Log History
                </span>
            </div>
            <div className={"row mt-1"}>
                <div className={"col-8"}>
                    <div className={"row"}>
                        <div className="col-md-1">
                            <label htmlFor="textInput" className="form-label">
                                ID
                            </label>
                            <input
                                type="number"
                                className={"form-control rounded-0"}
                                id="id"
                                onChange={
                                    e => setSearchParam({
                                        ...searchParam,
                                        id: parseInt(e.currentTarget.value)
                                    })
                                }
                                value={!!searchParam.id ? searchParam.id : ""}
                            />
                        </div>
                        <div className="col-md-1">
                            <label htmlFor="textInput" className="form-label">
                                Entity ID
                            </label>
                            <input
                                type="number"
                                className={"form-control rounded-0"}
                                id="entityId"
                                onChange={
                                    e => setSearchParam({
                                        ...searchParam,
                                        entityId: parseInt(e.currentTarget.value)
                                    })
                                }
                                value={!!searchParam.entityId ? searchParam.entityId : ""}
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="selectOption" className="form-label">
                                Entity Type
                            </label>
                            <select
                                className={"form-select rounded-0"}
                                id="entityType"
                                placeholder={"Select entity type"}
                                onChange={
                                    e => setSearchParam({
                                        ...searchParam,
                                        entityType: e.target.value
                                    })
                                }
                            >
                                <option value=""
                                        selected={searchParam.entityType == null}
                                        disabled
                                        hidden>
                                    Select entity type
                                </option>
                                {
                                    entityTypeSelect.map(item => <option key={item} value={item}>{item}</option>)
                                }
                            </select>
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="selectOption" className="form-label">
                                Actor
                            </label>
                            <input
                                type="text"
                                className={"form-control rounded-0"}
                                id="actor"
                                onChange={
                                    e => setSearchParam({
                                        ...searchParam,
                                        actor: e.target.value
                                    })
                                }
                                value={!!searchParam.actor ? searchParam.actor : ""}
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="selectOption" className="form-label">
                                Created date
                            </label>
                            <DatePicker
                                showIcon
                                showYearDropdown
                                className={"form-control w-100 rounded-0 clickable"}
                                onChange={(date) => {
                                    setSearchParam({
                                        ...searchParam,
                                        timeStamp: convertDateFromServer(date)
                                    })
                                }}
                                value={!!searchParam.timeStamp ? searchParam.timeStamp : ""}
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="selectOption" className="form-label">
                                Action type
                            </label>
                            <select
                                className={"form-select rounded-0"}
                                id="actionType"
                                placeholder={"Select action type"}
                                onChange={
                                    e => setSearchParam({
                                        ...searchParam,
                                        actionType: e.target.value
                                    })
                                }
                            >
                                <option value=""
                                        selected={searchParam.actionType == null}
                                        disabled
                                        hidden>
                                    Select action type
                                </option>
                                {
                                    actionTypeSelect.map(item => <option key={item} value={item}>{item}</option>)
                                }
                            </select>
                        </div>
                        <div className="col-md-2 d-flex align-items-end">
                            <button className="btn btn-secondary align-bottom rounded-0 w-100" disabled={loading}
                                    onClick={(e) => onClear(e)}>
                                Clear
                            </button>
                        </div>
                    </div>
                </div>


                {/*PAGINATION*/}
                <div className={"col-4 align-text-bottom"}>
                    <label htmlFor="pagination" className="form-label">
                        Page
                    </label>
                    <CustomPagination
                        pageInfo={pageInfo}
                        onPageChanged={onPageChange}
                        pageRangeDisplayed={3}
                        gotoProportion={"4"}
                        pagingProportion={"8"}
                    />
                </div>
            </div>

            {/*TABLE CHANGE LOG*/}
            <div style={containerStyle} className="mt-2 ag-theme-alpine ">
                <AgGridReact
                    overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                    overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No data founds</span>'}
                    ref={gridRef}
                    rowData={data}
                    columnDefs={columnDef}
                    defaultColDef={defaultColumnDef}
                    animateRows={true}
                    groupDefaultExpanded={1}
                    rowHeight={15}
                    onGridReady={onGridReady}
                    enableCellTextSelection={true}
                />
            </div>
        </div>
    )
};


export default ChangeLogManage;
