import {ITextContent} from "app/shared/model/text-content.model";

export interface ITranslation {
    id?: number;
    langId?: number;
    textContent?: ITextContent;
    transString?: string;
}

export const defaultValue: Readonly<ITranslation> = {};
