import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs.ts';

import React, {useEffect} from 'react';
import {Card} from 'reactstrap';
import {BrowserRouter} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';

import {useAppDispatch, useAppSelector} from 'app/config/store';
import {getSession} from 'app/shared/reducers/authentication';
import {getProfile} from 'app/shared/reducers/application-profile';
import Header from 'app/shared/layout/header/header';
import {hasAnyAuthority} from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import {AUTHORITIES} from 'app/config/constants';
import AppRoutes from 'app/routes';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getSession());
        dispatch(getProfile());
    }, []);

    const currentLocale = useAppSelector(state => state.locale.currentLocale);
    const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
    const isAdmin = useAppSelector(state =>
        hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.DEVELOPER])
    );
    const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
    const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
    const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

    const paddingTop = '65px';
    return (
        <BrowserRouter basename={baseHref}>
            <div className="app-container" style={{paddingTop}}>
                <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container"
                                toastClassName="toastify-toast"
                                autoClose={2500}
                                style={{wordBreak: 'break-word'}}
                />
                <ErrorBoundary>
                    <Header
                        isAuthenticated={isAuthenticated}
                        isAdmin={isAdmin}
                        currentLocale={currentLocale}
                        ribbonEnv={ribbonEnv}
                        isInProduction={isInProduction}
                        isOpenAPIEnabled={isOpenAPIEnabled}
                    />
                </ErrorBoundary>
                <div className="container-fluid view-container" id="app-view-container">
                    <Card className="mt-3">
                        <ErrorBoundary>
                            <AppRoutes/>
                        </ErrorBoundary>
                    </Card>
                </div>
            </div>
        </BrowserRouter>
    );
};

export default App;
