import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useAppDispatch, useAppSelector} from 'app/config/store';
import {deleteEntity, getEntity} from './language.reducer';

export const LanguageDeleteDialog = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();

  const [loadModal, setLoadModal] = useState(false);

  useEffect(() => {
    dispatch(getEntity(id));
    setLoadModal(true);
  }, []);

  const languageEntity = useAppSelector(state => state.language.entity);
  const updateSuccess = useAppSelector(state => state.language.updateSuccess);

  const handleClose = () => {
    navigate('/language');
  };

  useEffect(() => {
    if (updateSuccess && loadModal) {
      handleClose();
      setLoadModal(false);
    }
  }, [updateSuccess]);

  const confirmDelete = () => {
    dispatch(deleteEntity(languageEntity.id));
  };

  return (
      <Modal isOpen toggle={handleClose}>
          <ModalHeader toggle={handleClose} data-cy="languageDeleteDialogHeading">
              <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
          </ModalHeader>
          <ModalBody id="messageStringApp.language.delete.question">
              <Translate contentKey="messageStringApp.language.delete.question" interpolate={{id: languageEntity.id}}>
                  Are you sure you want to delete this Language?
              </Translate>
          </ModalBody>
          <ModalFooter>
              <Button color="secondary" onClick={handleClose}>
                  <FontAwesomeIcon icon="ban"/>
                  &nbsp;
                  <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button id="jhi-confirm-delete-language" data-cy="entityConfirmDeleteButton" color="danger"
                      onClick={confirmDelete}>
                  <FontAwesomeIcon icon="trash"/>
                  &nbsp;
                  <Translate contentKey="entity.action.delete">Delete</Translate>
              </Button>
          </ModalFooter>
      </Modal>
  );
};

export default LanguageDeleteDialog;
