import React, {useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useAppDispatch, useAppSelector} from 'app/config/store';

import {getEntity} from './language.reducer';

export const LanguageDetail = () => {
    const dispatch = useAppDispatch();

    const {id} = useParams<'id'>();

    useEffect(() => {
        dispatch(getEntity(id));
    }, []);

    const languageEntity = useAppSelector(state => state.language.entity);
    return (
        <Row>
            <Col md="8">
                <h2 data-cy="languageDetailsHeading">
                    <Translate contentKey="messageStringApp.language.detail.title">Language</Translate>
                </h2>
                <dl className="jh-entity-details">
                    <dt>
            <span id="id">
              <Translate contentKey="messageStringApp.language.id">Id</Translate>
            </span>
                    </dt>
                    <dd>{languageEntity.id}</dd>
                    <dt>
            <span id="langCode">
              <Translate contentKey="messageStringApp.language.langCode">Lang Code</Translate>
            </span>
                    </dt>
                    <dd>{languageEntity.langCode}</dd>
                </dl>
                <Button tag={Link} to="/language" replace color="info" data-cy="entityDetailsBackButton">
                    <FontAwesomeIcon icon="arrow-left"/>{' '}
                    <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
                </Button>
                &nbsp;
                <Button tag={Link} to={`/language/${languageEntity.id}/edit`} replace color="primary">
                    <FontAwesomeIcon icon="pencil-alt"/>{' '}
                    <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
                </Button>
            </Col>
        </Row>
    );
};

export default LanguageDetail;
